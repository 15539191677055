import LoadingSpinner from "components/LoadingSpinner";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { setOBCInfoFromAPIResponse, setOBCToken } from "reducers/OBCSlice";
import { Paths } from "Routes";
import store from "store";
import { OBCClient } from "clients";
import { CustomRedirect } from "../../../utils/CostumRedirect";
import { ErrorState } from "../index";
import { TemporaryErrorModal } from "../Error/TemporaryErrorModal";

const FullPageLoadingIndicator = () => (
  <div
    style={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      transitionDuration: "350ms",
    }}
  >
    <div style={{ margin: "auto" }}>
      <LoadingSpinner />
    </div>
  </div>
);

const OBCJumppad: React.FC = () => {
  const loc = useLocation();

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [errorLoadingCustomerData, setErrorLoadingCustomerData] =
    useState<ErrorState | null>(null);

  console.log(errorLoadingCustomerData);

  useEffect(() => {
    const params = new URLSearchParams(loc.search);
    const token = params.get("token");
    if (!!token) {
      store.dispatch(setOBCToken(token!));

      OBCClient.getOBCInfo(token!)
        .then((response) => {
          store.dispatch(setOBCInfoFromAPIResponse(response.data));
          setIsLoadingData(false);
        })
        .catch((error) => {
          setErrorLoadingCustomerData({
            error: {
              message: error.response?.message,
              status: error.response?.status,
            },
            show: true,
          });
          setIsLoadingData(false);
        });
    } else {
      setErrorLoadingCustomerData({
        error: { message: "", status: 400 },
        show: true,
      });
    }
  }, [loc]);

  const skipTimeslotSelection = !!store.getState().OBCInfo!.appointmentSlot;

  return (
    <>
      {isLoadingData ? (
        <FullPageLoadingIndicator />
      ) : !errorLoadingCustomerData ? (
        <CustomRedirect
          url={
            skipTimeslotSelection
              ? Paths.OBCScheduled
              : Paths.OBCSchedulingAssistant
          }
        />
      ) : (
        <></>
      )}
      {errorLoadingCustomerData && (
        <TemporaryErrorModal
          errorState={errorLoadingCustomerData}
          setErrorState={setErrorLoadingCustomerData}
        />
      )}
    </>
  );
};

export default OBCJumppad;
