import React, { PropsWithChildren } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setCustomerDataDirty,
  EventName,
  mostRecentEvent,
  OrderType,
  setAllFromAPIResponse,
  setSelectedPropertyIndex,
} from "reducers/onboardingInformationSlice";
import { Paths, RouteManager, StandaloneRoutes } from "Routes";
import store from "store";
import LoadingSpinner from "./LoadingSpinner";
import { Col, Row } from "react-bootstrap";

import PhoneSupportNoTitleView from "./PhoneSupportNoTitleView";
import {
  TechemModal,
  TechemModalBody,
  TechemModalButtonFooter,
  TechemModalHeader,
} from "@techem/ui-components";
import { TechemTheme } from "@techem/techem-theme";
import { styled } from "styletron-react";
import { generateApplicationEnvironmentFromLoginRedirectUrl } from "clients/auth/authUtils";
import { stringify } from "query-string";
import storage from "utils/storage";
import { OnboardingInformationClient } from "clients";
import { TrackerUtil } from "tracking/TrackerUtil";

const FullPageLoadingIndicator = () => (
  <div
    className="d-flex vw-100 vh-100"
    style={{
      transitionDuration: "350ms",
    }}
  >
    <div className="m-auto">
      <LoadingSpinner />
    </div>
  </div>
);

const HeaderView = styled(Col, {
  height: "100px",
  "@media (max-width: 767px)": {
    height: "80px",
  },
  backgroundColor: TechemTheme.colors.backgroundPrimary,
});

export const RefreshAfterApiCall = () => {
  store.dispatch(setCustomerDataDirty());

  // Ensure we stay on a standalone page if applicable.
  const urlParams =
    window.location.hash.split("?").length > 1
      ? `?${window.location.hash.split("?")[1]}`
      : "";
  window.location.href = `${window.location.origin}/#${urlParams}`;
};

const ApplicationInterceptor: React.FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const navigate = useNavigate();
  const loc = useLocation();
  const { t } = useTranslation();

  const [errorLoadingCustomerData, setErrorLoadingCustomerData] = useState<{
    error: { message: string; status: number };
    show: boolean;
  } | null>(null);

  const [isLoadingData, setIsLoadingData] = useState(
    !store.getState().onboardingInfo.userData.clientNumber
  );
  const [isFinalDestination, setIsFinalDestination] = useState(false);
  RouteManager.isFinal = false; // Prevents pages to be tracker that are intermediate and not the final step according to current customer data.

  const [queryParams] = useState<URLSearchParams>(
    new URLSearchParams(loc.search)
  );

  useEffect(() => {
    // Directly return if we're in the onboarding call flow.
    if (store.getState().OBCInfo.token) {
      setIsFinalDestination(true);
      RouteManager.isFinal = true;
      setIsLoadingData(false);
      return;
    }

    const transitionToAppropriateOnboardingStep = () => {
      const userData = store.getState().onboardingInfo.userData;

      // Display first steps if there is no flag placed in localstorage.
      // Whatever route we want to navigate to, first show the intro.
      if (!storage.isIntroCompleted()) {
        // Interception to add initial intro view if it hasn't been shown yet.
        // Always override whatever route we want to route to if the intro wasn't shown before.
        // Retain original query params.
        navigate(
          {
            pathname: Paths.FirstSteps,
            search: loc.search,
          },
          { replace: true }
        );
        return;
      }

      // Manually add query params taken from the login redirect url to our application environment.
      // This is to ensure a user gets to the desired location within the application, and also uses the pre-selected property they wanted in the first place.
      // A typical example is to click on a link in an e-mail and go to the according property right away after logging back in due to a timeout.
      if (!!queryParams.get("client_meta")) {
        const appEnvironment =
          generateApplicationEnvironmentFromLoginRedirectUrl();
        for (const key of Object.keys(appEnvironment)) {
          queryParams.append(key, appEnvironment[key]);
        }
      }

      // Load preferred route.
      // Can be either the current route when the user does a browser refresh or a query parameter when coming from an auth step where we can only direct to the base url.
      // Also use preferred route to check when we've arrived at our destination.
      let preferredRoute: string | null = queryParams.get("preferredRoute");
      if (!preferredRoute) {
        preferredRoute = loc.pathname;
      }

      // Redirect to destination path, only retaining relevant url search parameters.
      const urlParams = Object.create(null);
      queryParams.forEach(function (value, key) {
        if (key === "propertyNumber") {
          urlParams[key] = value;
        }
      });

      if (queryParams && queryParams.get("propertyNumber")) {
        // Already pre-selected property, so jump to according step for given property.
        const preselectedProperty = userData.properties.find(
          (property: any) =>
            property.propertyNumber === queryParams.get("propertyNumber")
        );
        if (preselectedProperty) {
          store.dispatch(
            setSelectedPropertyIndex(
              userData.properties.indexOf(preselectedProperty)
            )
          );

          var destinationPath = "";

          // Allow user to go to process overview from anywhere as long as they have a property selected.
          if (preferredRoute === Paths.ProgressOverview) {
            destinationPath = Paths.ProgressOverview;
          } else {
            // Not special case, so do default redirects.
            const mostRecentEventItem = mostRecentEvent(
              userData.properties[
                store.getState().onboardingInfo.selectedPropertyIndex
              ].orders.find(
                (order: any) => order.type === OrderType.TechemDirect
              )!.eventHistory
            );

            switch (mostRecentEventItem.nameOrderObject.name) {
              case EventName.OrderCompleted:
              case EventName.OnboardingAccessGranted:
                destinationPath = Paths.OnboardingOverview;
                break;

              case EventName.OnboardingActionRequired:
                destinationPath = Paths.OnboardingActionRequired;
                break;

              case EventName.PreferredOnSiteInspectionTimeslotsRequired:
                if (
                  preferredRoute &&
                  (preferredRoute === Paths.TDOrderOverview ||
                    preferredRoute === Paths.OnSiteInspectionInfo ||
                    preferredRoute ===
                      Paths.OnSiteInspectionSchedulingAssistant)
                ) {
                  // Go to preferred route.
                  destinationPath = preferredRoute;
                } else {
                  // Go to default route for the current step for the given property.
                  destinationPath = Paths.TDOrderOverview;
                }
                break;

              case EventName.PreferredOnSiteInspectionTimeslotsSet:
                destinationPath = Paths.OnSiteInspectionPreferredTimeslotsSet;
                break;

              case EventName.OnSiteInspectionScheduled:
                destinationPath = Paths.OnSiteInspectionAppointmentScheduled;
                break;

              case EventName.OnSiteInspectionIncomplete:
                destinationPath = Paths.OnSiteInspectionResultsPending;
                break;

              case EventName.OnSiteInspectionFollowUpRequired:
              case EventName.OnSiteInspectionCancelled:
                if (
                  preferredRoute &&
                  (preferredRoute === Paths.TDOrderOverview ||
                    preferredRoute === Paths.OnSiteInspectionInfo ||
                    preferredRoute ===
                      Paths.OnSiteInspectionSchedulingAssistant)
                ) {
                  // Go to preferred route.
                  destinationPath = preferredRoute;
                } else {
                  // Go to default route for the current step for the given property.
                  destinationPath = Paths.OnSiteInspectionSchedulingAssistant;
                }
                break;

              case EventName.InstallationStateRecorded:
                destinationPath = Paths.OnSiteInspectionResultsPending;
                break;

              case EventName.InstallationKitNeeded:
                destinationPath = Paths.InstallationKitInfo;
                break;

              case EventName.InstallationKitRequested:
                destinationPath = Paths.InstallationKitShippingPending;
                break;

              case EventName.InstallationKitSent:
                destinationPath = Paths.InstallationKitStatus;
                break;

              case EventName.InstallationKitInstalled:
                destinationPath = Paths.InstallationPreparationPending;
                break;

              case EventName.InstallationIntervalSet:
                destinationPath = Paths.InstallationEstimatedInterval;
                break;

              case EventName.PreferredInstallationTimeslotsRequired:
                if (
                  preferredRoute &&
                  (preferredRoute === Paths.InstallationInfo ||
                    preferredRoute === Paths.SchedulingAssistantInstallation)
                ) {
                  // Go to preferred route.
                  destinationPath = preferredRoute;
                } else {
                  // Go to default route for the current step for the given property.
                  destinationPath = Paths.InstallationInfo;
                }
                break;

              case EventName.PreferredInstallationTimeslotsSet:
                if (
                  preferredRoute &&
                  (preferredRoute ===
                    Paths.InstallationAppointmentPreferredTimeslotsSet ||
                    preferredRoute === Paths.InstallationPrecautions)
                ) {
                  // Go to preferred route.
                  destinationPath = preferredRoute;
                } else {
                  // Go to default route for the current step for the given property.
                  destinationPath =
                    Paths.InstallationAppointmentPreferredTimeslotsSet;
                }
                break;

              case EventName.InstallationTimeslotScheduled:
                if (
                  preferredRoute &&
                  (preferredRoute === Paths.InstallationAppointmentScheduled ||
                    preferredRoute === Paths.InstallationPrecautions)
                ) {
                  // Go to preferred route.
                  destinationPath = preferredRoute;
                } else {
                  // Go to default route for the current step for the given property.
                  destinationPath = Paths.InstallationAppointmentScheduled;
                }
                break;

              case EventName.InstallationFollowUpRequired:
              case EventName.InstallationTimeslotCancelled:
                if (
                  preferredRoute &&
                  (preferredRoute === Paths.InstallationInfo ||
                    preferredRoute === Paths.SchedulingAssistantInstallation)
                ) {
                  // Go to preferred route.
                  destinationPath = preferredRoute;
                } else {
                  // Go to default route for the current step for the given property.
                  destinationPath = Paths.SchedulingAssistantInstallation;
                }
                break;

              case EventName.InstallationCompleted:
              case EventName.OnboardingCompleted:
                if (
                  (preferredRoute &&
                    preferredRoute === Paths.InstallationCompleted) ||
                  preferredRoute === Paths.OnboardingCompleted
                ) {
                  // Go to preferred route.
                  destinationPath = preferredRoute;
                } else {
                  // Go to default route for the current step for the given property.
                  destinationPath = Paths.InstallationCompleted;
                }
                break;
            }
          }

          if (destinationPath === loc.pathname) {
            // If we're there, hide the loading indicator and show actual page.
            setIsFinalDestination(true);
            RouteManager.isFinal = true;
            setIsLoadingData(false);
          } else {
            navigate(
              {
                pathname: destinationPath,
                search: stringify(urlParams),
              },
              { replace: true }
            );
          }
        } else {
          const errMsg = `Property number from url (${queryParams.get(
            "propertyNumber"
          )}) does not match any properties for the given client (client number ${
            userData.clientNumber
          })`;
          console.error(errMsg);
          setIsLoadingData(false);
          setErrorLoadingCustomerData({
            error: { message: errMsg, status: 422 },
            show: true,
          });
        }
      } else {
        // Default fallback is the property selection page.
        // In case we're already there, show the page.
        // If not, redirect there.
        if (Object.values(StandaloneRoutes).indexOf(loc.pathname) > -1) {
          setIsFinalDestination(true);
          RouteManager.isFinal = true;
          setIsLoadingData(false);
        } else {
          // Default fallback to property selection unless there is a valid preferred path.
          navigate({
            pathname:
              Object.values(StandaloneRoutes).indexOf(preferredRoute) > -1
                ? preferredRoute
                : Paths.PropertySelection,
            search: stringify(urlParams),
          });
        }
      }
    };

    // Initially loading user data if necessary.
    if (
      !store.getState().OBCInfo.token &&
      (!store.getState().onboardingInfo.userData.clientNumber ||
        store.getState().onboardingInfo.isDirty)
    ) {
      OnboardingInformationClient.getCustomerData()
        .then((response) => {
          store.dispatch(setAllFromAPIResponse(response));

          // Choose the first Property if only one is available in userData and navigate to overview
          // Only transition if we initially wanted to go to the overview or to no specific route at all.
          // Some valid path further down the flow should still be allowed!
          if (
            response.userData.properties.length === 1 &&
            (loc.pathname === Paths.PropertySelection ||
              loc.pathname === Paths.Default)
          ) {
            store.dispatch(setSelectedPropertyIndex(0));

            navigate(
              {
                pathname: Paths.Default,
                search: `${loc.search}&propertyNumber=${response.userData.properties[0].propertyNumber}`,
              },
              { replace: true }
            );
            return;
          }

          // Handle case where we come back to the application
          // after a login call has been made.
          transitionToAppropriateOnboardingStep();
        })
        .catch((error) => {
          if (
            error.response &&
            (error.response.status !== 403 || error.response.status !== 401)
          ) {
            // TODO: Remove this when the Login is fixed.
            navigate({ pathname: Paths.LoginError });

            console.error(error);
            setIsLoadingData(false);
            setErrorLoadingCustomerData({
              error: {
                message: error.response.message,
                status: error.response.status,
              },
              show: true,
            });
          }
        });
    } else {
      transitionToAppropriateOnboardingStep();
    }
  }, [navigate, loc, queryParams]);

  return (
    <>
      {isLoadingData || !isFinalDestination ? (
        <FullPageLoadingIndicator />
      ) : (
        children
      )}

      <TechemModal
        closeable
        header={
          <TechemModalHeader
            title={
              errorLoadingCustomerData
                ? t(
                    errorLoadingCustomerData!.error.status === 404
                      ? "customerNotFoundErrorModalTitle"
                      : errorLoadingCustomerData!.error.status === 422
                      ? "propertyNumberInvalidErrorModalTitle"
                      : "generalErrorModalTitle"
                  )
                : ""
            }
          ></TechemModalHeader>
        }
        body={
          <TechemModalBody>
            <Row>
              <Col xs className="text-center copy">
                {errorLoadingCustomerData &&
                  t(
                    errorLoadingCustomerData!.error.status === 404
                      ? "customerNotFoundErrorModalDescription1"
                      : errorLoadingCustomerData!.error.status === 422
                      ? "propertyNumberInvalidErrorModalDescription1"
                      : "generalErrorModalDescription1"
                  )}
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="auto" sm={11} className="copy mt-3 mb-2">
                {errorLoadingCustomerData &&
                  t(
                    errorLoadingCustomerData!.error.status === 404
                      ? "customerNotFoundErrorModalDescription2"
                      : errorLoadingCustomerData!.error.status === 422
                      ? ""
                      : "generalErrorModalDescription2"
                  )}
              </Col>
            </Row>
            {errorLoadingCustomerData &&
              errorLoadingCustomerData!.error.status !== 422 && (
                <Row>
                  <Col xs className="text-center copy">
                    <HeaderView
                      lg={4}
                      md={7}
                      sm="auto"
                      xs="auto"
                      className="d-flex w-100"
                    >
                      <PhoneSupportNoTitleView />
                    </HeaderView>
                  </Col>
                </Row>
              )}
          </TechemModalBody>
        }
        footer={
          <TechemModalButtonFooter
            trackClickUsingTestId={(buttonIdentifier: string) => {
              TrackerUtil.trackBtnClickByIdentifier(
                buttonIdentifier,
                "load-customer-data-error-modal"
              );
            }}
            primary={{
              text: errorLoadingCustomerData
                ? t(
                    errorLoadingCustomerData!.error.status === 404
                      ? "customerNotFoundErrorModalCloseButton"
                      : errorLoadingCustomerData!.error.status === 422
                      ? "propertyNumberInvalidErrorModalCloseButton"
                      : "generalErrorModalCloseButton"
                  )
                : "",
              handleOnClick: () => {
                if (errorLoadingCustomerData!.error.status === 422) {
                  navigate(
                    {
                      pathname: Paths.PropertySelection,
                    },
                    { replace: true }
                  );
                } else {
                  setErrorLoadingCustomerData({
                    error: errorLoadingCustomerData!.error,
                    show: false,
                  });
                  setTimeout(() => {
                    setErrorLoadingCustomerData(null);
                  }, 1500);
                }
              },
              additionalTestId: "close-error-modal",
            }}
          />
        }
        isOpen={
          !!errorLoadingCustomerData &&
          !!errorLoadingCustomerData.error &&
          errorLoadingCustomerData.show
        }
        modalTrackingId="load-customer-data-error-modal"
        onClose={() => {
          if (errorLoadingCustomerData!.error.status === 422) {
            navigate(
              {
                pathname: Paths.PropertySelection,
              },
              { replace: true }
            );
          } else {
            setErrorLoadingCustomerData({
              error: errorLoadingCustomerData!.error,
              show: false,
            });
            setTimeout(() => {
              setErrorLoadingCustomerData(null);
            }, 1500);
          }
        }}
        additionalTestId="load-customer-data-error-modal"
      />
    </>
  );
};

export default ApplicationInterceptor;
