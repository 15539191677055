import React from "react";
import Header from "layout/Header";
import Footer from "layout/Footer";
import WizardStepsForOverallProgress from "components/WizardStepsForOverallProgress";
import { Row, Col, Container } from "react-bootstrap";
import { TechemTheme } from "@techem/techem-theme";
import { useLocation, useNavigate } from "react-router-dom";
import { Paths } from "Routes";
import { DetailedEventOverview } from "components/DetailedEventOverview";
import Title from "components/Title";
import { Trans, useTranslation } from "react-i18next";
import { OrderType, StoreObject } from "reducers/onboardingInformationSlice";
import { connect } from "react-redux";
import Icon, { ICONSIZE } from "components/Icon";
import checkedClipboardIcon from "assets/icons/png/3x/icn-clipboard-list-check.png";
import { Button } from "@techem/ui-components";
import { SIZE } from "baseui/button";

import { TrackerUtil } from "tracking/TrackerUtil";
import { CSSAssignBorderRadius } from "services/tools/CSSHelper";
import useDuration from "tools/hooks/useDuration";

const mapStateToProps = (state: any) => {
  return {
    onboardingInfo: state.onboardingInfo,
  };
};

interface Props {
  onboardingInfo?: StoreObject;
}

const InstallationInfoPage: React.FC<Props> = ({ onboardingInfo }) => {
  const navigate = useNavigate();
  const loc = useLocation();
  const { t } = useTranslation();
  const { duration: installationDuration } = useDuration("installation");

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header>
        <WizardStepsForOverallProgress
          clickOnShowAll={() => {
            navigate(
              {
                pathname: Paths.ProgressOverview,
                search: loc.search,
              },
              {
                state: { from: loc.pathname },
              }
            );
          }}
        />
      </Header>

      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="mb-md-3">
              <Title className="mx-1" title={t("installationInfoPageTitle")} />

              <Row className="justify-content-center mt-3 pt-1">
                <Col
                  style={{
                    backgroundColor: TechemTheme.colors.backgroundSecondary,
                    ...CSSAssignBorderRadius("8px"),
                  }}
                >
                  <Row className="justify-content-center">
                    <Col xs="auto" className="copy p-0 mt-3 pt-2">
                      <Icon
                        size={ICONSIZE.xxxlarge}
                        iconSrc={checkedClipboardIcon}
                        alt="clipboard-icon"
                      />
                    </Col>
                  </Row>

                  <Row className="justify-content-center">
                    <Col
                      xs={10}
                      className="copy p-0 pb-3 mt-3 mb-2 text-center"
                    >
                      <Trans i18nKey={"installationInfoPageContent"} />
                      <br />
                      <div
                        className="fw-bold"
                        style={{ color: TechemTheme.colors.accent }}
                      >
                        <Trans
                          i18nKey={"installationInfoPageContent2"}
                          values={{ hours: installationDuration }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col
              xs
              md={10}
              lg={8}
              className="copy text-bold pt-2 pt-md-3 m-2 mt-4 mx-0"
            >
              <span style={{ fontSize: "1.25rem", lineHeight: "2rem" }}>
                {t("installationInfoPageBulletSectionTitle")}
              </span>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs md={10} lg={8} className="copy my-2">
              {t("installationInfoPageBulletSectionDescription")}
            </Col>
          </Row>

          <Row className="justify-content-center pt-4">
            <Col
              xs={12}
              md={10}
              lg={8}
              className="mb-2 mb-md-3"
              style={{
                backgroundColor: TechemTheme.colors.backgroundSecondary,
                ...CSSAssignBorderRadius("8px"),
              }}
            >
              <Row className="justify-content-center">
                <Col
                  xs={10}
                  className="copy text-bold text-center pt-2 mt-4 pb-1"
                >
                  <span style={{ fontSize: "1.5rem", lineHeight: "2rem" }}>
                    {t("installationInfoPageAcknowledgementSectionTitle")}
                  </span>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col xs={10} className="copy p-0 my-2 pb-md-2 text-center">
                  <Trans
                    i18nKey="installationInfoPageAcknowledgementSectionDescription"
                    tOptions={{ skipPostProcessing: true }}
                  />
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col xs="auto" className="my-2 mb-4 py-2">
                  <Button
                    size={SIZE.large}
                    onClick={() => {
                      navigate({
                        pathname: Paths.SchedulingAssistantInstallation,
                        search: loc.search,
                      });
                    }}
                    additionalTestId="proceed-to-appointment-selection"
                    trackClickUsingTestId={(buttonIdentifier) => {
                      TrackerUtil.trackBtnClick(
                        `${TrackerUtil.getPageName()}_button_${buttonIdentifier}`,
                        `button_${buttonIdentifier}`
                      );
                    }}
                  >
                    {t(
                      "installationInfoPageProceedToAppointmentSelectionButtonText"
                    )}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="pt-4 px-0">
              <DetailedEventOverview
                data={
                  onboardingInfo!.userData.properties.length > 0
                    ? onboardingInfo!.userData.properties[
                        onboardingInfo!.selectedPropertyIndex
                      ].orders
                        .find((order) => order.type === OrderType.TechemDirect)!
                        .eventHistory.filter((eventItem) => !!eventItem.data)
                    : []
                }
              />
            </Col>
          </Row>
        </Container>
      </main>

      <Footer />
    </div>
  );
};

export default connect(mapStateToProps)(InstallationInfoPage);
