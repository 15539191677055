import { TechemTheme } from "@techem/techem-theme";
import { DetailedEventOverview } from "components/DetailedEventOverview";
import Icon, { ICONSIZE } from "components/Icon";
import hourglassIcon from "assets/icons/png/3x/icn-hourglass@3x.png";
import Title from "components/Title";
import Header from "layout/Header";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CSSAssignBorderRadius } from "services/tools/CSSHelper";
import useEventHistory from "tools/hooks/useEventHistory";
import { Trans, useTranslation } from "react-i18next";

import ProgressOverviewNavigationLink from "components/ProgressOverviewNavigationLink";

const OnboardingActionRequiredPage = () => {
  const { t } = useTranslation();
  const eventHistory = useEventHistory();

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <Container>
        <ProgressOverviewNavigationLink />
      </Container>

      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <Row className="justify-content-center mt-md-3 pt-md-3">
            <Col xs={12} md={10} lg={8} className="pb-1 mb-3">
              <Title className="mx-1" title={t("actionRequiredPageTitle")} />
            </Col>
          </Row>

          <Row className="justify-content-center mb-4">
            <Col
              xs={12}
              md={10}
              lg={8}
              style={{
                backgroundColor: TechemTheme.colors.backgroundSecondary,
                ...CSSAssignBorderRadius("8px"),
              }}
            >
              <Row className="justify-content-center">
                <Col xs="auto" className="copy p-0 mt-3 pt-2">
                  <Icon
                    iconSrc={hourglassIcon}
                    alt="hourglass"
                    size={ICONSIZE.xxxlarge}
                  />
                </Col>
              </Row>

              <Row
                className="justify-content-center"
                data-gi="on-site-inspection-appointment-info"
              >
                <Col xs={10} className="copy p-0 pb-3 mt-3 mb-2 text-center">
                  <Trans i18nKey="actionRequiredPageDescription" />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="pt-4 px-0">
              <DetailedEventOverview data={eventHistory} />
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
};

export default OnboardingActionRequiredPage;
