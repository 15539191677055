import de from "date-fns/locale/de";
import { parse, format } from "date-fns";
import { TimeOfDay } from "reducers/onboardingInformationSlice";

export enum DateFormat {
  DateWithShortWeekday = "E, dd.MM.yyyy",
  DateWithFullWeekday = "EEEE, dd. MMMM yyyy",
  DateWithShortWeekdayAndTime = "E, dd.MM.yyyy, HH:mm",
  DateSimple = "dd.MM.yyyy",
  DateWithFullMonth = "dd. MMMM yyyy",
  DateForAPICall = "yyyy-MM-dd",
  DateWeekdayOnly = "EEEE",
  DateWeek = "ww",
  DateYear = "yyyy",
}
export const DateDefaultLocale = de;

export const parseDate = (
  dateString: string,
  format: DateFormat = DateFormat.DateWithShortWeekday
) => {
  return parse(dateString, format, new Date(), {
    locale: de,
  });
};

export const formatDate = (
  date: Date,
  formatting: DateFormat = DateFormat.DateWithShortWeekday
) => {
  return format(date, formatting, {
    locale: de,
  });
};
export const formatTime = (date: Date, short = false) => {
  return `${format(date, "HH:mm", {
    locale: de,
  })}${short ? "" : " Uhr"}`;
};
export const formatDateAndTime = (
  date: Date,
  formatting: DateFormat = DateFormat.DateWithShortWeekday
) => {
  return `${formatDate(date, formatting)}, ${formatTime(date)}`;
};

export const timeOfDayBoundaries = (timeOfDay: TimeOfDay) => {
  switch (timeOfDay) {
    case TimeOfDay.Morning:
      return {
        startTime: "08:00",
        endTime: "12:00",
      };
    case TimeOfDay.Afternoon:
      return {
        startTime: "12:00",
        endTime: "16:30",
      };
    case TimeOfDay.AllDay:
      return {
        startTime: "08:00",
        endTime: "16:30",
      };
  }
};
