import React from "react";

function CustomRedirect(props: any) {
  setTimeout(() => {
    window.location.replace(props.url);
  }, 100); // Add some minor time delay to ensure tracking is being fired prior to this component being unmounted.
  return null;
}

const SetPasswordJumppadComponent: React.FC = () => {
  return (
    <CustomRedirect url="https://portal.techem.de/cms/de/login/login/passwortvergessen.html" />
  );
};

export default SetPasswordJumppadComponent;
