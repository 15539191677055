import React from "react";
import {
  WizardStepsContainer,
  WizardSteps,
  WizardStepsDescription,
} from "components/WizardSteps";
import { connect } from "react-redux";
import {
  EventHistoryEntry,
  Order,
  OrderType,
} from "reducers/onboardingInformationSlice";

const progressMilestones = [0, 40, 52, 90];
const showVOTAndEBSStepsAsRegularProgressValue = 10;

interface WizardStepsForOverallProgressProps {
  historyEntries: EventHistoryEntry[];
  clickOnShowAll?: () => void;
}

const mapStateToProps = (state: any) => {
  return {
    historyEntries:
      state.onboardingInfo &&
      state.onboardingInfo.userData.properties.length > 0
        ? state.onboardingInfo!.userData.properties[
            state.onboardingInfo!.selectedPropertyIndex
          ].orders.find(
            (order: Order) => order.type === OrderType.TechemDirect
          )!.eventHistory
        : undefined,
  };
};

const WizardStepsForOverallProgress: React.FC<
  WizardStepsForOverallProgressProps
> = ({ historyEntries, clickOnShowAll }) => {
  const progressData = progressMilestones.map((mileStoneNumber) => {
    return { progressValue: mileStoneNumber, shouldShow: true };
  });

  const showSecondStepAsRegular = historyEntries
    ? historyEntries!
        .map((entry) => entry.nameOrderObject.progress)
        .indexOf(showVOTAndEBSStepsAsRegularProgressValue) > -1
    : false;
  if (!showSecondStepAsRegular) {
    progressData[1].shouldShow = false;
    progressData[2].shouldShow = false;
  }

  const maxProgress = Math.max(
    ...historyEntries.map((entry) => entry.nameOrderObject.progress)
  );
  let currentStepIndex = -1;
  progressData.forEach((progressDataItem) => {
    if (maxProgress >= progressDataItem.progressValue) {
      if (progressDataItem.shouldShow) {
        currentStepIndex++;
      }
    }
  });

  const totalSteps = progressData.filter((item) => item.shouldShow).length;

  return (
    <WizardStepsContainer>
      <WizardSteps
        currentStep={currentStepIndex + 1}
        totalSteps={totalSteps}
        clickOnShowAll={clickOnShowAll}
      />
      <WizardStepsDescription
        currentStep={currentStepIndex + 1}
        totalSteps={totalSteps}
        clickOnShowAll={clickOnShowAll}
      />
    </WizardStepsContainer>
  );
};

export default connect(mapStateToProps)(WizardStepsForOverallProgress);
