import React from "react";
import { Col, Row } from "react-bootstrap";
import { styled } from "styletron-react";
import { useStyletron } from "baseui";

import backIcon from "assets/icons/png/3x/icn-chevron-left.png";
import Icon, { ICONSIZE, getPixelSize } from "components/Icon";
import { md, minBreakpoints } from "theme/mediaQueries";
import { TrackerUtil } from "tracking/TrackerUtil";
import { useMediaQuery } from "tools/hooks/useMediaQuery";

const IconCol = styled(Col, {
  paddingTop: "4px",
  height: "40px",
  [md]: {
    paddingTop: "3px",
    height: "56px",
  },
});
const IconWrapper = styled("div", {
  width: getPixelSize(ICONSIZE.medium),
  height: getPixelSize(ICONSIZE.medium),
  [md]: {
    width: getPixelSize(ICONSIZE.large),
    height: getPixelSize(ICONSIZE.large),
  },
});

const TitleWrapper = styled("div", {
  paddingTop: "0",
  paddingBottom: "0",
  alignItems: "center",
});

interface Props {
  onPrevClick?: () => void;
  title: string;
}

const TitleWithPrevPageLink: React.FC<Props> = ({ title, onPrevClick }) => {
  const [css] = useStyletron();
  const isMdOrAbove = useMediaQuery(`(min-width: ${minBreakpoints.md}px)`);

  return (
    <Row>
      {!!onPrevClick && (
        <IconCol xs="auto">
          <IconWrapper>
            <Icon
              iconSrc={backIcon}
              handleClick={() => {
                TrackerUtil.trackPrevBtnClick();
                onPrevClick();
              }}
              size={isMdOrAbove ? ICONSIZE.xlarge : ICONSIZE.large}
              alt="back button"
              additionalTestId="prev"
              className={!!onPrevClick ? "d-block" : "d-none"}
            />
          </IconWrapper>
        </IconCol>
      )}

      <Col
        xs
        className={`${
          isMdOrAbove ? "d-flex align-items-center justify-content-center" : ""
        } px-sm-3${!!onPrevClick ? " px-0" : ""}`}
      >
        <TitleWrapper data-gi="page-title-element">
          <h2
            className={`${css({ wordBreak: "break-word" })} text-center m-auto`}
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </TitleWrapper>
      </Col>

      {!!onPrevClick && (
        <IconCol xs="auto">
          <IconWrapper />
        </IconCol>
      )}
    </Row>
  );
};

export default TitleWithPrevPageLink;
