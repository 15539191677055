import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { Timeline } from "./event-overview/Timeline";
import { StatefulPanel } from "baseui/accordion";
import { Trans, useTranslation } from "react-i18next";
import chevronDownIcon from "assets/icons/png/3x/chevron-down-red@3x.png";
import Icon, { ICONSIZE } from "./Icon";
import { buildEventContent } from "services/EventHistoryMapper";
import { EventHistoryEntry } from "reducers/onboardingInformationSlice";
import { DateFormat, formatDate } from "tools/dev/DateTimeFormatHelper";
import Divider from "./Divider";
import { TimelineItem } from "./event-overview/TimeLineItem";

interface DetailedEventOverviewProps {
  data: EventHistoryEntry[];
}

export const DetailedEventOverview: React.FC<DetailedEventOverviewProps> = ({
  data,
}) => {
  const [expanded, setExpanded] = React.useState(true);
  const { t } = useTranslation();

  const sortEvents = (data: EventHistoryEntry[]) => {
    // TODO: Actually do sorting via event timestamp

    return [...data].reverse();
  };

  const mappedData = sortEvents(data).map((dataItem) => {
    const eventContent = buildEventContent(
      t,
      dataItem.nameOrderObject.name,
      dataItem.data
    );
    return eventContent
      ? {
          timeText: `${formatDate(
            new Date(dataItem.timestamp),
            DateFormat.DateWithShortWeekdayAndTime
          )} Uhr`,
          title: eventContent?.title,
          content: eventContent?.content,
          type: dataItem.nameOrderObject.name,
        }
      : undefined;
  });

  return (
    <>
      <Divider className="mt-0 mb-4" />
      <StatefulPanel
        initialState={{ expanded: expanded }}
        onChange={() => {
          setExpanded(!expanded);
        }}
        title={
          <div
            className="d-flex position-relative w-100 align-items-center p-0 ps-3"
            style={{
              transitionProperty: "all",
              transitionDuration: "300ms",
              transitionTimingFunction: "linear",
              backgroundColor: "transparent",
            }}
          >
            <div className="mr-2 ml-1">
              <div
                style={{
                  transform: expanded ? "rotate(0deg)" : "rotate(180deg)",
                  transition: "transform 300ms ease-in-out",
                }}
              >
                <Icon
                  iconSrc={chevronDownIcon}
                  size={ICONSIZE.small}
                  centered
                  alt="faq arrow icon"
                />
              </div>
            </div>
            <div className="ps-3">
              <span
                className="copy-l fw-bold"
                data-gi="detailed-event-history-title"
              >
                <Trans i18nKey="detailedEventOverviewTitle" />
              </span>
            </div>
          </div>
        }
        overrides={{
          Header: {
            style: {
              borderBottomWidth: 0,
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
              paddingBottom: 0,
            },
          },
          Content: {
            style: {
              borderBottomWidth: 0,
              transitionProperty: "height",
              transitionDuration: "300ms",
              transitionTimingFunction: "linear",
              backgroundColor: "transparent",
              paddingTop: "16px",
              paddingLeft: "36px",
              paddingRight: "0",
              paddingBottom: "0",
            },
            props: {
              "data-gi": "detailed-event-history",
            },
          },
          ToggleIcon: {
            style: {
              display: "none",
            },
          },
          PanelContainer: {
            style: {
              borderBottomWidth: "0",
            },
          },
        }}
      >
        <Timeline>
          {mappedData.map((item, index, array) =>
            item ? (
              <TimelineItem
                key={`detailed-event-item-${index + 1}`}
                checked={true}
                additionalTestId={`detailed-event-item-${index + 1} ${
                  item.type
                }${index + 1 === array.length ? " last" : ""}`}
                last={index === array.length - 1}
              >
                <Row>
                  <Col xs={12} className="ps-1">
                    <span className="copy" data-gi="event-item-time">
                      {item.timeText}
                    </span>
                  </Col>
                  <Col xs={12} className="ps-1">
                    <span className="copy fw-bold" data-gi="event-item-title">
                      {item.title}
                    </span>
                  </Col>
                  <Col xs={12} className="ps-1">
                    <span className="copy" data-gi="event-item-content">
                      {item.content}
                    </span>
                  </Col>
                </Row>
              </TimelineItem>
            ) : null
          )}
        </Timeline>
      </StatefulPanel>
    </>
  );
};
