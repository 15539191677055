import React from "react";
import Header from "layout/Header";
import Footer from "layout/Footer";
import WizardStepsForOverallProgress from "components/WizardStepsForOverallProgress";
import { Row, Col, Container } from "react-bootstrap";
import { TechemTheme } from "@techem/techem-theme";
import { useLocation, useNavigate } from "react-router-dom";
import { Paths } from "Routes";
import Title from "components/Title";
import PageDescription from "components/PageDescription";
import { Trans, useTranslation } from "react-i18next";
import {
  OrderType,
  StoreObject,
  TechemDirectOrder,
} from "reducers/onboardingInformationSlice";
import { connect } from "react-redux";
import { Button } from "@techem/ui-components";
import { SIZE } from "baseui/button";
import { translationKeyForFixedOption } from "tools/FixedOptionMapper";
import LoadingSpinner from "components/LoadingSpinner";

import { minBreakpoints } from "theme/mediaQueries";
import { useMediaQuery } from "tools/hooks/useMediaQuery";
import { TrackerUtil } from "tracking/TrackerUtil";
import { CSSAssignBorderRadius } from "services/tools/CSSHelper";

const mapStateToProps = (state: any) => {
  return {
    onboardingInfo: state.onboardingInfo,
  };
};

interface Props {
  onboardingInfo?: StoreObject;
}

const TDOrderOverviewPage: React.FC<Props> = ({ onboardingInfo }) => {
  const navigate = useNavigate();
  const loc = useLocation();
  const { t } = useTranslation();

  const isMdOrAbove = useMediaQuery(`(min-width: ${minBreakpoints.md}px)`);

  const getPropertyInfo = () => {
    return onboardingInfo
      ? onboardingInfo.userData.properties[onboardingInfo.selectedPropertyIndex]
      : undefined;
  };
  const getTDOrder = () => {
    return onboardingInfo
      ? (getPropertyInfo()?.orders.find(
          (order) => order.type === OrderType.TechemDirect
        )! as TechemDirectOrder)
      : undefined;
  };

  return !!onboardingInfo?.userData.clientNumber ? (
    <div className="d-flex flex-column min-vh-100">
      <Header>
        <WizardStepsForOverallProgress
          clickOnShowAll={() => {
            navigate(
              {
                pathname: Paths.ProgressOverview,
                search: loc.search,
              },
              {
                state: { from: loc.pathname },
              }
            );
          }}
        />
      </Header>

      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="mb-3 mb-md-4">
              <Title
                className="mx-1 mb-3"
                title={t("TDOrderOverviewPageTitle")}
              />

              <PageDescription className="mx-1">
                {t("TDOrderOverviewPageDescription")}
              </PageDescription>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col
              xs={12}
              md={10}
              lg={8}
              style={{
                backgroundColor: TechemTheme.colors.backgroundSecondary,
                ...CSSAssignBorderRadius("8px"),
              }}
              className="px-md-4"
            >
              <Row className="mx-1 my-3 my-md-4">
                <Col xs className="copy p-0 text-bold">
                  <Trans i18nKey="TDOrderOverviewPageContentTitle" />
                </Col>
              </Row>

              <Row
                className="mx-1 py-2 py-md-3 copy justify-content-end"
                style={{
                  borderBottomColor: TechemTheme.colors.inputBorder,
                  borderBottomWidth: "1px",
                  borderBottomStyle: "solid",
                }}
              >
                <Col xs="auto" md className="ps-0">
                  <Trans i18nKey="TDOrderOverviewPageContentNumberOfApartmentsLabel"></Trans>
                </Col>
                <Col xs className="px-0 d-md-none"></Col>
                <Col
                  xs="auto"
                  md
                  className={`pe-0${
                    isMdOrAbove ? " text-bold text-left" : " text-right"
                  }`}
                >
                  <Trans
                    i18nKey="TDOrderOverviewPageContentNumberOfApartmentsValue"
                    values={{
                      numberOfApartments: getPropertyInfo()!.numberOfApartments,
                    }}
                  ></Trans>
                </Col>
              </Row>

              <Row
                className="mx-1 py-2 py-md-3 copy justify-content-end"
                style={{
                  borderBottomColor: TechemTheme.colors.inputBorder,
                  borderBottomWidth: "1px",
                  borderBottomStyle: "solid",
                }}
              >
                <Col xs="auto" md className="ps-0">
                  <Trans i18nKey="TDOrderOverviewPageContentCentralHeatingLabel"></Trans>
                </Col>
                <Col xs className="px-0 d-md-none"></Col>
                <Col
                  xs="auto"
                  md
                  className={`pe-0${
                    isMdOrAbove ? " text-bold text-left" : " text-right"
                  }`}
                >
                  {t(
                    translationKeyForFixedOption(
                      getTDOrder()!.data.centralHeatingAvailable
                    ),
                    { skipPostProcessing: true }
                  )}
                </Col>
              </Row>

              <Row
                className="mx-1 py-2 copy justify-content-end"
                style={{
                  borderBottomColor: TechemTheme.colors.inputBorder,
                  borderBottomWidth: "1px",
                  borderBottomStyle: "solid",
                }}
              >
                <Col xs="auto" md className="ps-0">
                  <Trans i18nKey="TDOrderOverviewPageContentHotWaterDeliveryLabel"></Trans>
                </Col>
                <Col xs className="px-0 d-md-none"></Col>
                <Col
                  xs="auto"
                  md
                  className={`pe-0${
                    isMdOrAbove ? " text-bold text-left" : " text-right"
                  }`}
                >
                  {t(
                    translationKeyForFixedOption(
                      getTDOrder()!.data.centralHotWaterDelivery
                    ),
                    { skipPostProcessing: true }
                  )}
                </Col>
              </Row>

              <Row
                className="mx-1 py-2 py-md-3 copy justify-content-end"
                style={{
                  borderBottomColor: TechemTheme.colors.inputBorder,
                  borderBottomWidth: "1px",
                  borderBottomStyle: "solid",
                }}
              >
                <Col xs="auto" md className="ps-0">
                  <Trans i18nKey="TDOrderOverviewPageContentHeatingTypeLabel"></Trans>
                </Col>
                <Col xs className="px-0 d-md-none"></Col>
                <Col
                  xs="auto"
                  md
                  className={`pe-0${
                    isMdOrAbove ? " text-bold text-left" : " text-right"
                  }`}
                >
                  {t(
                    translationKeyForFixedOption(
                      getTDOrder()!.data.heatingType
                    ),
                    { skipPostProcessing: true }
                  )}
                </Col>
              </Row>

              <Row
                className="mx-1 py-2 py-md-3 copy justify-content-end"
                style={{
                  borderBottomColor: TechemTheme.colors.inputBorder,
                  borderBottomWidth: "1px",
                  borderBottomStyle: "solid",
                }}
              >
                <Col xs="auto" md className="ps-0">
                  <Trans i18nKey="TDOrderOverviewPageContentEnergySourcesLabel"></Trans>
                </Col>
                <Col xs className="px-0 d-md-none"></Col>
                <Col
                  xs="auto"
                  md
                  className={`pe-0${
                    isMdOrAbove ? " text-bold text-left" : " text-right"
                  }`}
                >
                  {getTDOrder()!
                    .data.heatingSources.map((optionKey) =>
                      t(translationKeyForFixedOption(optionKey), {
                        skipPostProcessing: true,
                      })
                    )
                    .join(", ")}
                </Col>
              </Row>

              <Row
                className="mx-1 py-2 py-md-3 copy justify-content-end"
                style={{
                  borderBottomColor: TechemTheme.colors.inputBorder,
                  borderBottomWidth: "1px",
                  borderBottomStyle: "solid",
                }}
              >
                <Col xs="auto" md className="ps-0">
                  <Trans i18nKey="TDOrderOverviewPageContentClientLabel"></Trans>
                </Col>
                <Col xs className="px-0 d-md-none"></Col>
                <Col
                  xs="auto"
                  md
                  className={`pe-0${
                    isMdOrAbove ? " text-bold text-left" : " text-right"
                  }`}
                >
                  <Row>
                    <Col xs={12}>
                      {t(
                        translationKeyForFixedOption(
                          onboardingInfo.userData.contractData.personalData
                            .gender
                        ),
                        { skipPostProcessing: true }
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      {`${onboardingInfo.userData.contractData.personalData.firstName} ${onboardingInfo.userData.contractData.personalData.lastName}`}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      {`${getTDOrder()!.clientContactDetails.phoneNumber}`}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      {`${getTDOrder()!.clientContactDetails.email}`}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row
                className="mx-1 py-2 py-md-3 copy justify-content-end"
                style={{
                  borderBottomColor: TechemTheme.colors.inputBorder,
                  borderBottomWidth: "1px",
                  borderBottomStyle: "solid",
                }}
              >
                <Col xs="auto" md className="ps-0">
                  <Trans i18nKey="TDOrderOverviewPageContentContractAddressLabel"></Trans>
                </Col>
                <Col xs className="px-0 d-md-none"></Col>
                <Col
                  xs="auto"
                  md
                  className={`pe-0${
                    isMdOrAbove ? " text-bold text-left" : " text-right"
                  }`}
                >
                  <Row>
                    <Col xs={12}>
                      {`${onboardingInfo.userData.contractData.address.street} ${onboardingInfo.userData.contractData.address.houseNumber}`}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      {`${onboardingInfo.userData.contractData.address.zip} ${onboardingInfo.userData.contractData.address.city}`}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row
                className="mx-1 py-2 py-md-3 copy justify-content-end"
                style={{
                  borderBottomColor: TechemTheme.colors.inputBorder,
                  borderBottomWidth: "1px",
                  borderBottomStyle: "solid",
                }}
              >
                <Col xs="auto" md className="ps-0">
                  <Trans i18nKey="TDOrderOverviewPageContentPropertyAddressLabel"></Trans>
                </Col>
                <Col xs className="px-0 d-md-none"></Col>
                <Col
                  xs="auto"
                  md
                  className={`pe-0${
                    isMdOrAbove ? " text-bold text-left" : " text-right"
                  }`}
                >
                  <Row className="justify-content-end">
                    <Col xs={12}>
                      {`${getPropertyInfo()!.address.street} ${
                        getPropertyInfo()!.address.houseNumber
                      }`}
                    </Col>
                  </Row>
                  <Row className="justify-content-end">
                    <Col xs={12}>
                      {`${getPropertyInfo()!.address.zip} ${
                        getPropertyInfo()!.address.city
                      }`}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row
                className="mx-1 py-2 py-md-3 copy justify-content-end"
                style={{
                  borderBottomColor: TechemTheme.colors.inputBorder,
                  borderBottomWidth: "1px",
                  borderBottomStyle: "solid",
                }}
              >
                <Col xs="auto" md className="ps-0">
                  <Trans i18nKey="TDOrderOverviewPageContentBillingPeriodLabel"></Trans>
                </Col>
                <Col xs className="px-0 d-md-none"></Col>
                <Col
                  xs="auto"
                  md
                  className={`pe-0${
                    isMdOrAbove ? " text-bold text-left" : " text-right"
                  }`}
                >
                  {`${getTDOrder()!.data.billingPeriod.start} - ${
                    getTDOrder()!.data.billingPeriod.end
                  }`}
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col xs="auto" className="my-4 py-2">
                  <Button
                    size={SIZE.large}
                    onClick={() => {
                      navigate({
                        pathname: Paths.OnSiteInspectionInfo,
                        search: loc.search,
                      });
                    }}
                    additionalTestId="start-onboarding"
                    trackClickUsingTestId={(buttonIdentifier) => {
                      TrackerUtil.trackBtnClick(
                        `${TrackerUtil.getPageName()}_button_${buttonIdentifier}`,
                        `button_${buttonIdentifier}`
                      );
                    }}
                  >
                    <Trans i18nKey="TDOrderOverviewPageStartButtonLabel"></Trans>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </main>

      <Footer />
    </div>
  ) : (
    <Container className="m-auto" style={{ transitionDuration: "350ms" }}>
      <Row className="justify-content-center">
        <Col xs="auto" className="d-flex vh-100">
          <LoadingSpinner topLevelStylingOverrides={{ margin: "auto" }} />
        </Col>
      </Row>
    </Container>
  );
};

export default connect(mapStateToProps)(TDOrderOverviewPage);
