import { SIZE } from "baseui/button";
import { TrackerUtil } from "../../../tracking/TrackerUtil";
import { Button } from "@techem/ui-components";
import React from "react";
import { Paths } from "../../../Routes";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  stretched?: boolean;
}

export const CheckListButton: React.FC<Props> = ({ stretched }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const handleGoToChecklistButtonClick = () => {
    navigate({
      pathname: Paths.OBCChecklist,
      search: location.search,
    });
  };

  return (
    <Button
      size={SIZE.large}
      stretched={!!stretched}
      onClick={() => {
        handleGoToChecklistButtonClick();
      }}
      additionalTestId="go-to-checklist"
      trackClickUsingTestId={(buttonIdentifier) => {
        TrackerUtil.trackBtnClick(
          `${TrackerUtil.getPageName()}_button_${buttonIdentifier}`,
          `button_${buttonIdentifier}`
        );
      }}
    >
      {t("OBCScheduledPageGoToChecklistButtonText")}
    </Button>
  );
};
