import { RefreshAfterApiCall } from "components/ApplicationInterceptor";
import PageDescription from "components/PageDescription";
import PhoneSupportNoTitleView from "components/PhoneSupportNoTitleView";
import TimeSlotPicker from "components/timeslot/TimeSlotPicker";
import TitleWithPrevPageLink from "components/TitleWithPrevPageLink";
import React, { useContext, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { OBCSetTimeslotResultOutcome } from "reducers/OBCSlice";
import { OrderType } from "reducers/onboardingInformationSlice";
import { RootState } from "reducers/rootReducer";
import { Paths } from "Routes";
import { styled } from "styletron-react";
import { TechemTheme } from "@techem/techem-theme";
import {
  DateFormat,
  formatDate,
  formatTime,
} from "tools/dev/DateTimeFormatHelper";
import { TrackerUtil } from "tracking/TrackerUtil";
import {
  TechemModal,
  TechemModalBody,
  TechemModalButtonFooter,
  TechemModalHeader,
} from "@techem/ui-components";
import Header from "layout/Header";
import Footer from "layout/Footer";
import { OrtecTimeSlotContext } from "contexts/OrtecTimeSlotContext";

const setHref = (toUrl: string) => {
  window.location.href = `${window.location.origin}/#${toUrl}${
    window.location.hash.split("?").length > 1
      ? "?" + window.location.hash.split("?")[1]
      : ""
  }`;
};

const HeaderView = styled(Col, {
  height: "100px",
  "@media (max-width: 767px)": {
    height: "80px",
  },
  backgroundColor: TechemTheme.colors.backgroundPrimary,
});

const OnSiteInspectionSchedulingOrtecPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loc = useLocation();
  const {
    minDate,
    maxDate,
    dateCandidate,
    timeSlotCandidate,
    unavailableDays,
    availableTimeSlots,
    handleSetDateCandidate,
    setTimeSlotCandidate,
    submitTimeslot,
  } = useContext(OrtecTimeSlotContext);

  const orderId: string =
    useSelector((state: RootState) => {
      const onboardingInfo = state.onboardingInfo;
      return onboardingInfo!.userData.properties.length > 0
        ? onboardingInfo!.userData.properties[
            onboardingInfo!.selectedPropertyIndex
          ].orders.find((order) => order.type === OrderType.TechemDirect)
            ?.orderNumber
        : "";
    }) || "";
  const [confirmTimeslotModalOpen, setConfirmTimeslotModalOpen] =
    useState(false);
  const [isSubmittingTimeslot, setIsSubmittingTimeslot] = useState(false);
  const [errorSendingTimeslot, setErrorSendingTimeslot] = useState<{
    errorType: string;
    show: boolean;
  } | null>(null);

  const handleTimeSlotSubmission = () => {
    setIsSubmittingTimeslot(true);
    submitTimeslot("inspection", orderId, timeSlotCandidate!)
      .then((response) => {
        if (response.data.outcome === OBCSetTimeslotResultOutcome.Success) {
          RefreshAfterApiCall();
        } else {
          setErrorSendingTimeslot({
            errorType: response.data.outcome,
            show: true,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setErrorSendingTimeslot({
          errorType: `${error}`,
          show: true,
        });
      })
      .finally(() => {
        setIsSubmittingTimeslot(false);
        setConfirmTimeslotModalOpen(false);
      });
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />

      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <TitleWithPrevPageLink
            onPrevClick={() => {
              navigate(
                {
                  pathname: Paths.OnSiteInspectionInfo,
                  search: loc.search,
                },
                { replace: true }
              );
            }}
            title={t("schedulingAssistantOnSiteInspectionPageTitle")}
          />
          <Row className="justify-content-center">
            <Col xs={12} md={8} lg={6} className="mb-4 px-3">
              <PageDescription className="mx-1 text-center">
                <Trans i18nKey="schedulingAssistantOnSiteInspectionViaOrtecPageDescription" />
              </PageDescription>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8}>
              <TimeSlotPicker
                minDate={minDate}
                maxDate={maxDate}
                availableTimeSlots={availableTimeSlots}
                unavailableDays={unavailableDays}
                dateCandidate={dateCandidate}
                timeSlotCandidate={timeSlotCandidate}
                handleSetDateCandidate={handleSetDateCandidate}
                handleSetTimeSlotCandidate={setTimeSlotCandidate}
                handleOpenModal={setConfirmTimeslotModalOpen}
                title={t(
                  "schedulingAssistantOnSiteInspectionPageTimeslotSelectionTitle"
                )}
              />
            </Col>
          </Row>

          <TechemModal
            header={
              <TechemModalHeader
                title={t("ortecBookAppointmentConfirmationModalTitle")}
              />
            }
            body={
              <TechemModalBody>
                <Row className="justify-content-center">
                  <Col xs={12} className="text-center copy mt-3">
                    <Trans i18nKey="ortecBookAppointmentConfirmationModalDescription" />
                  </Col>
                </Row>
                <Row className="mt-4 justify-content-center">
                  <Col xs="auto" className="text-center copy-l">
                    <Trans
                      i18nKey="ortecBookAppointmentConfirmationModalContent"
                      values={{
                        dateTerm: !!timeSlotCandidate
                          ? formatDate(
                              new Date(timeSlotCandidate!.from),
                              DateFormat.DateWithFullWeekday
                            )
                          : "",
                        timeStartTerm: !!timeSlotCandidate
                          ? formatTime(new Date(timeSlotCandidate!.from), true)
                          : "",
                        timeEndTerm: !!timeSlotCandidate
                          ? formatTime(new Date(timeSlotCandidate!.till), true)
                          : "",
                      }}
                    />
                  </Col>
                </Row>
              </TechemModalBody>
            }
            footer={
              <TechemModalButtonFooter
                trackClickUsingTestId={(buttonIdentifier: string) => {
                  TrackerUtil.trackBtnClickByIdentifier(
                    buttonIdentifier,
                    "modal-confirm-timeslot"
                  );
                }}
                primary={{
                  text: t(
                    "ortecBookAppointmentConfirmationModalSendTimeslotButtonText"
                  ),
                  handleOnClick: () => {
                    handleTimeSlotSubmission();
                  },
                  additionalTestId: "confirm-timeslot",
                  isLoading: isSubmittingTimeslot,
                }}
                secondary={{
                  text: t(
                    "ortecBookAppointmentConfirmationModalCancelButtonText"
                  ),
                  handleOnClick: () => {
                    setConfirmTimeslotModalOpen(false);
                  },
                  additionalTestId: "cancel",
                }}
              />
            }
            isOpen={confirmTimeslotModalOpen}
            modalTrackingId="modal-confirm-timeslot"
            onClose={() => {
              setConfirmTimeslotModalOpen(false);
            }}
            additionalTestId="confirmation-modal"
          />
        </Container>
      </main>

      <Footer />

      <TechemModal
        closeable
        header={
          <TechemModalHeader
            title={
              errorSendingTimeslot
                ? t(
                    errorSendingTimeslot!.errorType ===
                      OBCSetTimeslotResultOutcome.ErrorSlotFullyBooked
                      ? "OBCSchedulingAssistantErrorAlreadyBookedModalTitle"
                      : "generalErrorModalTitle"
                  )
                : ""
            }
          />
        }
        body={
          <TechemModalBody>
            <Row>
              <Col xs className="text-center copy">
                {errorSendingTimeslot && (
                  <Trans
                    i18nKey={
                      errorSendingTimeslot!.errorType ===
                      OBCSetTimeslotResultOutcome.ErrorSlotFullyBooked
                        ? "OBCSchedulingAssistantErrorAlreadyBookedModalContent1"
                        : "generalErrorModalDescription1"
                    }
                    tOptions={{ skipPostProcessing: true }}
                  />
                )}
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="auto" sm={11} className="copy mt-3 mb-2">
                {errorSendingTimeslot && (
                  <Trans
                    i18nKey={
                      errorSendingTimeslot!.errorType ===
                      OBCSetTimeslotResultOutcome.ErrorSlotFullyBooked
                        ? "OBCSchedulingAssistantErrorAlreadyBookedModalContent2"
                        : "generalErrorModalDescription2"
                    }
                    tOptions={{ skipPostProcessing: true }}
                  />
                )}
              </Col>
            </Row>
            {errorSendingTimeslot &&
              errorSendingTimeslot!.errorType !==
                OBCSetTimeslotResultOutcome.ErrorSlotFullyBooked && (
                <Row>
                  <Col xs className="text-center copy">
                    <HeaderView
                      lg={4}
                      md={7}
                      sm="auto"
                      xs="auto"
                      className="d-flex w-100"
                    >
                      <PhoneSupportNoTitleView />
                    </HeaderView>
                  </Col>
                </Row>
              )}
          </TechemModalBody>
        }
        footer={
          <TechemModalButtonFooter
            trackClickUsingTestId={(buttonIdentifier: string) => {
              TrackerUtil.trackBtnClickByIdentifier(
                buttonIdentifier,
                "send-timeslot-error-modal"
              );
            }}
            primary={{
              text: errorSendingTimeslot
                ? t(
                    errorSendingTimeslot!.errorType ===
                      OBCSetTimeslotResultOutcome.ErrorSlotFullyBooked
                      ? "OBCSchedulingAssistantErrorAlreadyBookedModalButtonText"
                      : "generalErrorModalCloseButton"
                  )
                : "",
              handleOnClick: () => {
                setErrorSendingTimeslot({
                  errorType: errorSendingTimeslot!.errorType,
                  show: false,
                });

                if (
                  !!errorSendingTimeslot &&
                  errorSendingTimeslot!.errorType ===
                    OBCSetTimeslotResultOutcome.ErrorSlotFullyBooked
                ) {
                  // Refresh the page, i.e. just go back to Jumppad.
                  setHref(Paths.Default);
                } else {
                  setTimeout(() => {
                    setErrorSendingTimeslot(null);
                  }, 1500);
                }
              },
              additionalTestId: "close-error-modal",
            }}
          />
        }
        isOpen={
          !!errorSendingTimeslot &&
          !!errorSendingTimeslot.errorType &&
          errorSendingTimeslot.show
        }
        modalTrackingId="send-timeslot-error-modal"
        onClose={() => {
          setErrorSendingTimeslot({
            errorType: errorSendingTimeslot!.errorType,
            show: false,
          });
          setTimeout(() => {
            setErrorSendingTimeslot(null);
          }, 1500);
        }}
        additionalTestId="send-timeslot-error-modal"
      />
    </div>
  );
};

export default OnSiteInspectionSchedulingOrtecPage;
