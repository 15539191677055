export const API_BASE_URL: string = process.env.REACT_APP_API_BASE_URL || "";
export const API_MIETER_BASE_URL: string =
  process.env.REACT_APP_API_MIETER_BASE_URL || "";

export const DISABLE_FLOW_DEEP_LINKING_PROTECTION: boolean =
  process.env.REACT_APP_DISABLE_FLOW_DEEP_LINKING_PROTECTION === "true";

// DEVELOPMENT
export const SHOULD_USE_MOCKED_DATA: boolean =
  process.env.REACT_APP_USE_MOCKED_DATA === "true";
export const IS_DEV_LOCAL = process.env.REACT_APP_DEV_LOCAL === "true";

// ETRACKER
// It's our etracker account identifier. It's client side. It's not secure at all. Just etracker's naming.
export const ETRACKER_SECURE_CODE: string =
  process.env.REACT_APP_ETRACKER_SECURE_CODE || "";

export const FB_PIXEL_ID: string = process.env.REACT_APP_FB_PIXEL_ID || "";
export const GA_TRACKING_ID: string =
  process.env.REACT_APP_GA_TRACKING_ID || "";

// SURVICATE
export const SURVICATE_SURVEY_ID_INSTALLATION =
  process.env.REACT_APP_SURVICATE_SURVEY_ID_INSTALLATION;
export const SURVICATE_SURVEY_ID_VOT =
  process.env.REACT_APP_SURVICATE_SURVEY_ID_VOT;

// GITLAB PIPELINE
export const GITLAB_PIPELINE_ID = process.env.REACT_APP_PIPELINE_ID;
export const GITLAB_PIPELINE_CREATED_AT =
  process.env.REACT_APP_PIPELINE_CREATED_AT;
