/* DAY OF DATE INDEX */

export const DAY_OF_DATE_INDEX_SUNDAY = 0;
export const DAY_OF_DATE_INDEX_MONDAY = 1;
export const DAY_OF_DATE_INDEX_TUESDAY = 2;
export const DAY_OF_DATE_INDEX_WEDNESDAY = 3;
export const DAY_OF_DATE_INDEX_THURSDAY = 4;
export const DAY_OF_DATE_INDEX_FRIDAY = 5;
export const DAY_OF_DATE_INDEX_SATURDAY = 6;

export const PREFERRED_TIMESLOT_ALL_DAY_APARTMENT_THRESHOLD = 2;
