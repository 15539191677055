import React from "react";
import { LoginErrorPage } from "./LoginErrorPage";

interface Props {}

const LoginPage: React.FC<Props> = () => {
  return <LoginErrorPage />;

  // return (
  //   <div className="d-flex flex-column w-100 h-auto min-vh-100 position-absolute top-0 start-0">
  //     <Container
  //       className="py-5 m-auto position-relative"
  //       style={{ transitionDuration: "350ms" }}
  //     >
  //       <Row className="justify-content-center">
  //         <Col xs="auto">
  //           <Logo src={logoRef} alt="techem logo" className="d-flex" />
  //         </Col>
  //       </Row>
  //
  //       <Row className="justify-content-center">
  //         <Col
  //           xs="auto"
  //           style={{ marginTop: "65px" }}
  //           className="mb-3 text-center"
  //         >
  //           <h3>{t("loginPageTitle")}</h3>
  //         </Col>
  //       </Row>
  //
  //       <Row className="justify-content-center">
  //         <Col
  //           xs="auto"
  //           md={10}
  //           lg={7}
  //           xl={6}
  //           className="mt-2 mb-3 text-center"
  //         >
  //           <span className="copy">
  //             <Trans
  //               i18nKey="loginPageDescription"
  //               tOptions={{ skipPostProcessing: true }}
  //             />
  //           </span>
  //         </Col>
  //       </Row>
  //
  //       <Row className="justify-content-center mb-5">
  //         <Col xs="auto" className="mt-4">
  //           <Button
  //             size={SIZE.large}
  //             onClick={() => {
  //               setState({ ...state, loginInProgress: true });
  //               AuthClient.login();
  //             }}
  //             isLoading={state.loginInProgress}
  //             additionalTestId="start-onboarding"
  //             trackClickUsingTestId={(buttonIdentifier) => {
  //               TrackerUtil.trackBtnClick(
  //                 `${TrackerUtil.getPageName()}_button_${buttonIdentifier}`,
  //                 `button_${buttonIdentifier}`
  //               );
  //             }}
  //           >
  //             {t("loginPageButtonText")}
  //           </Button>
  //         </Col>
  //       </Row>
  //     </Container>
  //     <div className="d-flex w-100 position-absolute bottom-0">
  //       <div
  //         className="m-auto text-center"
  //         style={{
  //           lineHeight: "10px",
  //           height: "26px",
  //         }}
  //       >
  //         <span
  //           style={{
  //             fontSize: "10px",
  //             color: TechemTheme.colors.backgroundPrimary,
  //           }}
  //         >
  //           {`${GITLAB_PIPELINE_ID} started at ${GITLAB_PIPELINE_CREATED_AT}`}
  //         </span>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default LoginPage;
