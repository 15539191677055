import React from "react";
import Header from "./Header";
import Footer from "./Footer";

/*
      +--------------------------------+  ^
      |         Fixed Header           |  |
      +--------------------------------+  |
      |                                |  |
      |                                |  |
      |                                |  |
      |         Growing main           |  | min-height: 100vh
      |                                |  |
      |                                |  |
      |                                |  |
      +--------------------------------+  |
      |                                |  |
      |         Fixed Footer           |  |
      |                                |  |
      +--------------------------------+  v
*/
const DefaultLayout: React.FC = ({ children }) => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <main className="flex-grow-1 flex-shrink-1">{children}</main>
      <Footer />
    </div>
  );
};

export default DefaultLayout;
