import { KIND } from "baseui/button";
import {
  OBCTimeSlot,
  OBCTimeslotDataItem,
} from "pages/OBCFlow/OBCSchedulingAssistant/OBCSchedulingAssistantPage";
import React, { Dispatch, SetStateAction } from "react";
import { Col, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import TimeSlotButton from "./OrtecTimeSlotButton";

interface TimeSlotListProps {
  availableTimeSlots: OBCTimeslotDataItem[];
  dateCandidate: Date | undefined;
  timeSlotCandidate: OBCTimeSlot | undefined;
  handleSetTimeSlotCandidate: Dispatch<SetStateAction<OBCTimeSlot | undefined>>;
}

const TimeSlotList: React.FC<TimeSlotListProps> = (props) => {
  const {
    availableTimeSlots,
    dateCandidate,
    timeSlotCandidate,
    handleSetTimeSlotCandidate,
  } = props;

  return (
    <>
      <Row className="mb-3">
        <Col xs className="text-bold copy-l">
          <Trans i18nKey="OBCSchedulingAssistantTimeslotSelectionSectionTitle" />
        </Col>
      </Row>
      <Row className="justify-content-center">
        {availableTimeSlots !== undefined &&
          availableTimeSlots
            .find((item) => item.date.valueOf() === dateCandidate!.valueOf())!
            .availableTimeslots.map((item, index) => {
              const from = new Date(item.from);
              return (
                <Col xs={6} key={from.toString()} className="pb-3">
                  <TimeSlotButton
                    index={index}
                    timeSlot={item}
                    kind={
                      !!timeSlotCandidate &&
                      new Date(timeSlotCandidate.from).valueOf() ===
                        from.valueOf()
                        ? KIND.primary
                        : KIND.secondary
                    }
                    handleSetOBCTimeSlotCandidate={handleSetTimeSlotCandidate}
                    additionalTestId={`start-${new Date(
                      item.from
                    ).toLocaleTimeString("en", {
                      timeStyle: "short",
                      hour12: false,
                    })} end-${new Date(item.till).toLocaleTimeString("en", {
                      timeStyle: "short",
                      hour12: false,
                    })}${
                      !!timeSlotCandidate &&
                      new Date(timeSlotCandidate.from).valueOf() ===
                        from.valueOf()
                        ? " selected"
                        : ""
                    }`}
                  />
                </Col>
              );
            })}
      </Row>
    </>
  );
};

export default TimeSlotList;
