import React from "react";
import Header from "layout/Header";
import Footer from "layout/Footer";
import { Row, Col, Container } from "react-bootstrap";
import Title from "components/Title";
import PageDescription from "components/PageDescription";
import { Trans, useTranslation } from "react-i18next";

import { connect } from "react-redux";
import {
  DateFormat,
  formatDate,
  formatTime,
} from "tools/dev/DateTimeFormatHelper";
import { OBCInfoStoreData } from "reducers/OBCSlice";
import imageRef from "assets/images/obc-confirmation-banner.jpg";
import imageMinRef from "assets/images/obc-confirmation-banner-wide-min.jpg";
import { Paths } from "Routes";
import HintBox from "components/HintBox";
import cameraIcon from "assets/icons/png/3x/icn-camera@3x.png";
import listCheckIcon from "assets/icons/png/3x/list-check-light@3x.png";
import { minBreakpoints } from "theme/mediaQueries";
import { useMediaQuery } from "tools/hooks/useMediaQuery";
import { CustomRedirect } from "../../../utils/CostumRedirect";
import { PhotoUploadButton } from "./PhotoUploadButton";
import { CheckListButton } from "./CheckListButton";

const mapStateToProps = (state: any) => {
  return {
    OBCInfo: state.OBCInfo,
  };
};

interface Props {
  OBCInfo?: OBCInfoStoreData;
}

const OBCScheduledPage: React.FC<Props> = ({ OBCInfo }) => {
  const { t } = useTranslation();
  const isMdOrAbove = useMediaQuery(`(min-width: ${minBreakpoints.md}px)`);
  // TODO: Turn on again when Pharus allows PhotoUploads
  const enablePhotoUpload = false; // temparary disable Photoupload see: https://jira.techem-agile.de/browse/PKD-2799
  const photosRecentlyUploaded = true;
  //const photosRecentlyUploaded = !!OBCInfo?.photosRecentlyUploaded;

  return !!OBCInfo && !!OBCInfo.appointmentSlot ? (
    <div className="d-flex flex-column min-vh-100">
      <Header />

      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} className="text-center">
              <Title
                className="mx-1 text-center justify-content-center"
                title={t("OBCScheduledPageTitle")}
              />

              <Row className="justify-content-center my-4 rounded-topleft rounded-bottomright">
                <Col xs={12} className="pl-lg-0">
                  <img
                    style={
                      isMdOrAbove
                        ? {
                            width: "100%",
                            height: "350px",
                            objectFit: "cover",
                            objectPosition: "0 25%",
                          }
                        : {
                            height: "auto",
                          }
                    }
                    className="mw-100 rounded-topleft rounded-bottomright"
                    src={isMdOrAbove ? imageMinRef : imageRef}
                    alt="man with tablet"
                  />
                </Col>
              </Row>

              <PageDescription forceBreak className="mx-1">
                <Trans
                  i18nKey={
                    photosRecentlyUploaded
                      ? "OBCScheduledPageDescriptionWithUploadedPhoto1"
                      : "OBCScheduledPageDescription1"
                  }
                  values={{
                    weekday: formatDate(
                      new Date(OBCInfo!.appointmentSlot!.from),
                      DateFormat.DateWeekdayOnly
                    ),
                  }}
                  tOptions={{ skipPostProcessing: true }}
                >
                  <span
                    className="fw-bold"
                    dangerouslySetInnerHTML={{
                      __html: t("OBCScheduledPageDescription1DateTerm", {
                        dateTerm: formatDate(
                          new Date(OBCInfo!.appointmentSlot!.from),
                          DateFormat.DateWithFullMonth
                        ),
                        fromTime: formatTime(
                          new Date(OBCInfo!.appointmentSlot!.from),
                          true
                        ),
                        toTime: formatTime(
                          new Date(OBCInfo!.appointmentSlot!.to),
                          true
                        ),
                      }),
                    }}
                  ></span>
                </Trans>
              </PageDescription>

              {enablePhotoUpload && (
                <PageDescription forceBreak className="mx-1">
                  <Trans
                    i18nKey={
                      photosRecentlyUploaded
                        ? "OBCScheduledPageDescriptionWithUploadedPhoto2"
                        : "OBCScheduledPageDescription2"
                    }
                    values={{
                      photoCount: OBCInfo.numberOfRecentlyUploadedFotos,
                    }}
                  />
                </PageDescription>
              )}

              <HintBox
                iconSrc={photosRecentlyUploaded ? listCheckIcon : cameraIcon}
                title={t(
                  photosRecentlyUploaded
                    ? "OBCScheduledPageHintTitleWithUploadedPhoto"
                    : "OBCScheduledPageHintTitle"
                )}
                text={t(
                  photosRecentlyUploaded
                    ? "OBCScheduledPageHintDescriptionWithUploadedPhoto"
                    : "OBCScheduledPageHintDescription"
                )}
              />

              {enablePhotoUpload && (
                <Row className="justify-content-center">
                  <Col xs={10} sm={7} md={5} lg={4} xl={4} className="mt-2">
                    <PhotoUploadButton
                      photosRecentlyUploaded={photosRecentlyUploaded}
                    />
                  </Col>
                </Row>
              )}
              {!isMdOrAbove && (
                <Row
                  className={`justify-content-center ${
                    isMdOrAbove ? "pt-4" : ""
                  }`}
                >
                  <Col xs={12} sm={7} md={5} lg={4} xl={4} className="mb-2">
                    <CheckListButton stretched />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </main>

      <Footer />
    </div>
  ) : (
    <CustomRedirect url={Paths.OBC} />
  );
};

export default connect(mapStateToProps)(OBCScheduledPage);
