import { TechemTheme } from "@techem/techem-theme";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import {
  OrderType,
  StoreObject,
  TechemDirectOrder,
} from "reducers/onboardingInformationSlice";
import { CSSAssignBorderRadius } from "services/tools/CSSHelper";
import { styled } from "styletron-react";
import { minBreakpoints } from "theme/mediaQueries";
import { translationKeyForFixedOption } from "tools/FixedOptionMapper";
import { useMediaQuery } from "tools/hooks/useMediaQuery";

const HorizontalLine = styled("hr", {
  backgroundColor: "unset", // need because _reboot.scss causes some problems
  borderBottomColor: TechemTheme.colors.inputBorder,
  borderBottomWidth: "1px",
  margin: 0,
  borderBottomStyle: "solid",
});

const OverViewRow = styled(Row, {
  lineHeight: "38px",
});

interface OrderOverviewProps {
  onboardingInfo: StoreObject;
}

const OrderOverview: React.FC<OrderOverviewProps> = ({ onboardingInfo }) => {
  const { t } = useTranslation();
  const isMdOrAbove = useMediaQuery(`(min-width: ${minBreakpoints.md}px)`);

  const property = onboardingInfo
    ? onboardingInfo.userData.properties[onboardingInfo.selectedPropertyIndex]
    : undefined;

  const order = onboardingInfo
    ? (property?.orders.find(
        (order) => order.type === OrderType.TechemDirect
      )! as TechemDirectOrder)
    : undefined;

  return (
    <Col
      xs={12}
      md={10}
      lg={8}
      style={{
        backgroundColor: TechemTheme.colors.backgroundSecondary,
        ...CSSAssignBorderRadius("8px"),
      }}
      className="p-md-4 mt-md-2"
    >
      <Row className="m-md-2">
        <Col className="p-md-0">
          <OverViewRow className="px-md-3 m-md-0 mx-1 py-2">
            <Col xs className="copy-xl p-0 text-bold">
              <Trans i18nKey="TDOrderOverviewPageContentTitle" />
            </Col>
          </OverViewRow>

          <OverViewRow className="px-md-3 m-md-0 mx-1 py-2 copy justify-content-end">
            <Col xs="auto" md className="ps-0">
              <Trans i18nKey="TDOrderOverviewPageContentNumberOfApartmentsLabel" />
            </Col>
            <Col xs className="px-0 d-md-none" />
            <Col
              xs="auto"
              md
              className={`${
                isMdOrAbove ? "text-bold text-left" : "text-right"
              } pe-0`}
            >
              <Trans
                i18nKey="TDOrderOverviewPageContentNumberOfApartmentsValue"
                values={{
                  numberOfApartments: property!.numberOfApartments,
                }}
              ></Trans>
            </Col>
          </OverViewRow>
          <HorizontalLine />

          <OverViewRow className="px-md-3 m-md-0 mx-1 py-2 copy justify-content-end">
            <Col xs="auto" md className="ps-0">
              <Trans i18nKey="TDOrderOverviewPageContentCentralHeatingLabel"></Trans>
            </Col>
            <Col xs className="px-0 d-md-none"></Col>
            <Col
              xs="auto"
              md
              className={`${
                isMdOrAbove ? "text-bold text-left" : "text-right"
              } pe-0`}
            >
              {t(
                translationKeyForFixedOption(
                  order!.data.centralHeatingAvailable
                ),
                { skipPostProcessing: true }
              )}
            </Col>
          </OverViewRow>
          <HorizontalLine />

          <OverViewRow className="px-md-3 m-md-0 mx-1 py-2 copy justify-content-end">
            <Col xs="auto" md className="ps-0">
              <Trans i18nKey="TDOrderOverviewPageContentHotWaterDeliveryLabel"></Trans>
            </Col>
            <Col xs className="px-0 d-md-none"></Col>
            <Col
              xs="auto"
              md
              className={`${
                isMdOrAbove ? "text-bold text-left" : "text-right"
              } pe-0`}
            >
              {t(
                translationKeyForFixedOption(
                  order!.data.centralHotWaterDelivery
                ),
                { skipPostProcessing: true }
              )}
            </Col>
          </OverViewRow>
          <HorizontalLine />

          <OverViewRow className="px-md-3 m-md-0 mx-1 py-2 copy justify-content-end">
            <Col xs="auto" md className="ps-0">
              <Trans i18nKey="TDOrderOverviewPageContentHeatingTypeLabel"></Trans>
            </Col>
            <Col xs className="px-0 d-md-none"></Col>
            <Col
              xs="auto"
              md
              className={`${
                isMdOrAbove ? "text-bold text-left" : "text-right"
              } pe-0`}
            >
              {t(translationKeyForFixedOption(order!.data.heatingType), {
                skipPostProcessing: true,
              })}
            </Col>
          </OverViewRow>
          <HorizontalLine />

          <OverViewRow className="px-md-3 m-md-0 mx-1 py-2 copy justify-content-end">
            <Col xs="auto" md className="ps-0">
              <Trans i18nKey="TDOrderOverviewPageContentEnergySourcesLabel"></Trans>
            </Col>
            <Col xs className="px-0 d-md-none"></Col>
            <Col
              xs="auto"
              md
              className={`${
                isMdOrAbove ? "text-bold text-left" : "text-right"
              } pe-0`}
            >
              {order!.data.heatingSources
                .map((optionKey) =>
                  t(translationKeyForFixedOption(optionKey), {
                    skipPostProcessing: true,
                  })
                )
                .join(", ")}
            </Col>
          </OverViewRow>
          <HorizontalLine />

          <OverViewRow className="px-md-3 m-md-0 mx-1 py-2 copy justify-content-end">
            <Col xs="auto" md className="ps-0">
              <Trans i18nKey="TDOrderOverviewPageContentClientLabel"></Trans>
            </Col>
            <Col xs className="px-0 d-md-none"></Col>
            <Col
              xs="auto"
              md
              className={`${
                isMdOrAbove ? "text-bold text-left" : "text-right"
              } pe-0`}
              style={{
                lineHeight: "24px",
                marginTop: "6px",
                marginBottom: "8px",
              }}
            >
              <Row>
                <Col xs={12}>
                  {t(
                    translationKeyForFixedOption(
                      onboardingInfo.userData.contractData.personalData.gender
                    ),
                    { skipPostProcessing: true }
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  {`${onboardingInfo.userData.contractData.personalData.firstName} ${onboardingInfo.userData.contractData.personalData.lastName}`}
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  {`${order!.clientContactDetails.phoneNumber}`}
                </Col>
              </Row>
              <Row>
                <Col xs={12}>{`${order!.clientContactDetails.email}`}</Col>
              </Row>
            </Col>
          </OverViewRow>
          <HorizontalLine />

          <OverViewRow className="px-md-3 m-md-0 mx-1 py-2 copy justify-content-end">
            <Col xs="auto" md className="ps-0">
              <Trans i18nKey="TDOrderOverviewPageContentContractAddressLabel"></Trans>
            </Col>
            <Col xs className="px-0 d-md-none"></Col>
            <Col
              xs="auto"
              md
              className={`${
                isMdOrAbove ? "text-bold text-left" : "text-right"
              } pe-0`}
              style={{
                lineHeight: "24px",
                marginTop: "6px",
                marginBottom: "8px",
              }}
            >
              <Row>
                <Col xs={12}>
                  {`${onboardingInfo.userData.contractData.address.street} ${onboardingInfo.userData.contractData.address.houseNumber}`}
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  {`${onboardingInfo.userData.contractData.address.zip} ${onboardingInfo.userData.contractData.address.city}`}
                </Col>
              </Row>
            </Col>
          </OverViewRow>
          <HorizontalLine />

          <OverViewRow className="px-md-3 m-md-0 mx-1 py-2 copy justify-content-end">
            <Col xs="auto" md className="ps-0">
              <Trans i18nKey="TDOrderOverviewPageContentPropertyAddressLabel"></Trans>
            </Col>
            <Col xs className="px-0 d-md-none"></Col>
            <Col
              xs="auto"
              md
              className={`pe-0${
                isMdOrAbove ? " text-bold text-left" : " text-right"
              }`}
              style={{
                lineHeight: "24px",
                marginTop: "6px",
                marginBottom: "8px",
              }}
            >
              <Row className="justify-content-end">
                <Col xs={12}>
                  {`${property!.address.street} ${
                    property!.address.houseNumber
                  }`}
                </Col>
              </Row>
              <Row className="justify-content-end">
                <Col xs={12}>
                  {`${property!.address.zip} ${property!.address.city}`}
                </Col>
              </Row>
            </Col>
          </OverViewRow>
          <HorizontalLine />

          <OverViewRow className="px-md-3 m-md-0 mx-1 py-2 copy justify-content-end">
            <Col xs="auto" md className="ps-0">
              <Trans i18nKey="TDOrderOverviewPageContentBillingPeriodLabel"></Trans>
            </Col>
            <Col xs className="px-0 d-md-none"></Col>
            <Col
              xs="auto"
              md
              className={`${
                isMdOrAbove ? "text-bold text-left" : "text-right"
              } pe-0`}
            >
              {`${order!.data.billingPeriod.start} - ${
                order!.data.billingPeriod.end
              }`}
            </Col>
          </OverViewRow>
        </Col>
      </Row>
    </Col>
  );
};

export default OrderOverview;
