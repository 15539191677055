import { ABTesting } from "./reducers/abTestingSlice";
import store from "./store";

const initABTesting = (): ABTesting => {
  const queryParams = new URLSearchParams(window.location.search);

  decideABTestingScenarios(queryParams);

  return store.getState().abTesting;
};

const decideABTestingScenarios = (queryParams: URLSearchParams) => {};

export default initABTesting;
