// These media queries are used with Styletron/BaseWeb
// Keep in sync with the media queries used in the scss globals!

export const minBreakpoints = {
  // xs < 576
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

// Screensizes:
// "xs" is smaller 576. Use the default styles for this screen size
export const sm = `@media screen and (min-width: ${minBreakpoints.sm}px)`;
export const md = `@media screen and (min-width: ${minBreakpoints.md}px)`;
export const lg = `@media screen and (min-width: ${minBreakpoints.lg}px)`;
export const xl = `@media screen and (min-width: ${minBreakpoints.xl}px)`;
export const xxl = `@media screen and (min-width: ${minBreakpoints.xxl}px)`;
