import { useEffect, useState } from "react";
import { TrackerUtil } from "../../tracking/TrackerUtil";

export function useModalTracking(
  isModalOpen: boolean,
  modalTrackingId?: string
) {
  const [previousOpenState, setPreviousOpenState] = useState(false);

  useEffect(() => {
    if (!modalTrackingId) {
      return;
    }

    if (isModalOpen && !previousOpenState) {
      TrackerUtil.trackModalAppearance(modalTrackingId);
    }
    setPreviousOpenState(isModalOpen);
  }, [isModalOpen, modalTrackingId, previousOpenState]);
}
