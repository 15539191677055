import { createSlice } from "@reduxjs/toolkit";

export const ABTestingScenarios = {};

// Use this part of the store to store the selected variants of A/B tests.
// This way it's easy to show/hide or enable/disable certain parts at any location
// of the application for tests.
export type ABTesting = {};
export const initialABTesting: ABTesting = {};

export const abTestingSlice = createSlice({
  name: "abTesting",
  initialState: initialABTesting,
  reducers: {},
});

//export const {} = abTestingSlice.actions;

export default abTestingSlice.reducer;
