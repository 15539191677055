import React, { PropsWithChildren, ReactElement } from "react";
import {
  createHashRouter,
  createRoutesFromElements,
  Outlet,
  Route,
} from "react-router-dom";

import useScrollToTop from "tools/hooks/useScrollToTop";
import usePathBasedPageTracking from "tools/hooks/usePathBasedPageTracking";

import LoginPage from "pages/Miscellaneous/Login/LoginPage";
import PropertySelectionPage from "pages/Flow/PropertySelection/PropertySelectionPage";
import TDOrderOverviewPage from "pages/Flow/TDOrderOverview/TDOrderOverviewPage";
import ProgressOverviewPage from "pages/Flow/ProgressOverview/ProgressOverviewPage";
import OnSiteInspectionInfoPage from "pages/Flow/OnSiteInspectionInfo/OnSiteInspectionInfoPage";
import OnSiteInspectionAppointmentScheduledPage from "pages/Flow/OnSiteInspectionAppointmentScheduled/OnSiteInspectionAppointmentScheduledPage";
import OnSiteInspectionResultsPendingPage from "pages/Flow/OnSiteInspectionResultsPending/OnSiteInspectionResultsPendingPage";
import InstallationKitInfoPage from "pages/Flow/InstallationKitInfo/InstallationKitInfoPage";
import InstallationKitStatusPage from "pages/Flow/InstallationKitStatus/InstallationKitStatusPage";
import InstallationAppointmentPreferredTimeslotsSetPage from "pages/Flow/InstallationAppointmentPreferredTimeslotsSet/InstallationAppointmentPreferredTimeslotsSetPage";
import InstallationAppointmentScheduledPage from "pages/Flow/InstallationAppointmentScheduled/InstallationAppointmentScheduledPage";
import InstallationPrecautionsPage from "pages/Flow/InstallationPrecautions/InstallationPrecautionsPage";
import InstallationCompletedPage from "pages/Flow/InstallationCompleted/InstallationCompletedPage";
import OnboardingCompletedPage from "pages/Flow/OnboardingCompleted/OnboardingCompletedPage";
import DefaultRouteComponent from "pages/Miscellaneous/DefaultRouteComponent/DefaultRouteComponent";
import ApplicationInterceptor from "components/ApplicationInterceptor";
import ContactChannelsPage from "pages/Flow/ContactChannels/ContactChannelsPage";
import OnSiteInspectionPreferredTimeslotsSetPage from "pages/Flow/OnSiteInspectionPreferredTimeslotsSet/OnSiteInspectionPreferredTimeslotsSetPage";
import InstallationKitShippingPendingPage from "pages/Flow/InstallationKitShippingPending/InstallationKitShippingPendingPage";
import InstallationPreparationPendingPage from "pages/Flow/InstallationPreparationPending/InstallationPreparationPendingPage";
import InstallationInfoPage from "pages/Flow/InstallationInfo/InstallationInfoPage";
import SetPasswordJumppadComponent from "pages/Miscellaneous/SetPassword/SetPasswordJumppad";
import OBCJumppad from "pages/OBCFlow/OBCJumppad/OBCJumppadPage";
import OBCSchedulingAssistantPage from "pages/OBCFlow/OBCSchedulingAssistant/OBCSchedulingAssistantPage";
import OBCScheduledPage from "pages/OBCFlow/OBCScheduled/OBCScheduledPage";
import OBCChecklistPage from "pages/OBCFlow/OBCChecklist/OBCChecklistPage";
import InstallationSchedulingAssistantInterceptor from "pages/Flow/SchedulingAssistantInstallation/InstallationSchedulingAssistantInterceptor";
import OrtecAvailabilityProvider from "contexts/OrtecAvailabilityContext";
import OnSiteInspectionSchedulingAssistantInterceptor from "pages/Flow/OnSiteInspectionSchedulingAssistant/OnSiteInspectionSchedulingAssistantInterceptor";
import OnboardingOverviewPage from "pages/Flow/OnboardingOverview/OnboardingOverviewPage";
import InstallationEstimatedIntervalPage from "pages/Flow/InstallationEstimatedInterval/InstallationEstimatedIntervalPage";
import OnboardingActionRequiredPage from "pages/Flow/OnboardingActionRequired/OnboardingActionRequiredPage";
import OnboardingFirstStepsPage from "pages/Flow/OnboardingFirstSteps/OnboardingFirstStepsPage";
import { LoginErrorPage } from "./pages/Miscellaneous/Login/LoginErrorPage";

// Routes that don't need any pre-stored data and can therefore be accessed directly.
export const DatalessRoutes = {
  Login: "/login",
  LoginError: "/login-error",
  SetPassword: "/set-password",
  OBC: "/onboarding-call",
};

export const StandaloneRoutes = {
  ContactChannels: "/contact-channels",
  PropertySelection: "/select-property",
  FirstSteps: "/first-steps",
};

// Routes that require a selected property to appear.
export const OrderRelatedRoutes = {
  OnboardingOverview: "/overview",
  OnboardingActionRequired: "/action-required",
  TDOrderOverview: "/td-order-overview",
  OnSiteInspectionInfo: "/on-site-inspection-info",
  OnSiteInspectionSchedulingAssistant:
    "/on-site-inspection-scheduling-assistant",
  OnSiteInspectionPreferredTimeslotsSet:
    "/on-site-inspection-preferred-timeslots-set",
  OnSiteInspectionAppointmentScheduled:
    "/on-site-inspection-appointment-scheduled",
  OnSiteInspectionResultsPending: "/on-site-inspection-results-pending",
  InstallationKitInfo: "/installation-kit-info",
  InstallationKitShippingPending: "/installation-kit-shipping-pending",
  InstallationKitStatus: "/installation-kit-status",
  InstallationPreparationPending: "/installation-preparation-pending",
  InstallationInfo: "/installation-info",
  InstallationEstimatedInterval: "/estimated-interval",
  SchedulingAssistantInstallation: "/scheduling-assistant-installation",
  InstallationAppointmentPreferredTimeslotsSet:
    "/installation-appointment-preferred-timeslots-set",
  InstallationAppointmentScheduled: "/installation-appointment-scheduled",
  InstallationPrecautions: "/installation-precautions",
  InstallationCompleted: "/installation-completed",
  OnboardingCompleted: "/onboarding-completed",

  ProgressOverview: "/progress",
};

export const OBCRelatedRoutes = {
  OBCSchedulingAssistant: "/onboarding-call-scheduling-assistant",
  OBCScheduled: "/onboarding-call-scheduled",
};
export const OBCRelatedStandaloneRoutes = {
  OBCChecklist: "/onboarding-call-checklist",
  OBCPhotoUpload: "/onboarding-call-photo-upload",
};

export const Paths = {
  ...DatalessRoutes,
  ...StandaloneRoutes,
  ...OrderRelatedRoutes,
  ...OBCRelatedRoutes,
  ...OBCRelatedStandaloneRoutes,

  Default: "/",
};

export class RouteManager {
  static isFinal = true;
  static isValidPath = (pathname: string) => {
    return (
      RouteManager.isFinal &&
      Object.entries({
        ...DatalessRoutes,
        ...StandaloneRoutes,
        ...OrderRelatedRoutes,
        ...OBCRelatedRoutes,
        ...OBCRelatedStandaloneRoutes,
      })
        .map((entry) => entry[1])
        .indexOf(pathname) >= 0
    );
  };
}

const Routes = () => {
  // Had to extract this from the beginning of the component
  // (which is generally the correct way, as per hook rule #1: https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level).
  // Now this is being added as part (i.e. an invisible wrapper) of the router itself
  // and not outside of it. This conforms to react-router v6 docs.
  const RouterDependents = () => {
    usePathBasedPageTracking();
    useScrollToTop();
    return <Outlet />;
  };

  const RouteGuardian = ({ children }: PropsWithChildren<{}>) => {
    // Important! Don't forget to use a unique key to
    // ensure each page component has their own "dedicated" instance of
    // ApplicationInterceptor which starts with a clean slate.
    return (
      <ApplicationInterceptor key={Math.random().toString()}>
        {children}
      </ApplicationInterceptor>
    );
  };

  const guardedRoutes = (
    routes: ReactElement<{ path: string; element: JSX.Element }>[]
  ) => {
    return routes.map((originalRouteElement) => {
      return (
        <Route
          key={originalRouteElement.props.path}
          path={originalRouteElement.props.path}
          element={
            <RouteGuardian>{originalRouteElement.props.element}</RouteGuardian>
          }
        />
      );
    });
  };

  return (
    <Route element={<RouterDependents />}>
      <Route path={Paths.Login} element={<LoginPage />} />
      <Route path={Paths.LoginError} element={<LoginErrorPage />} />,
      <Route
        path={Paths.SetPassword}
        element={<SetPasswordJumppadComponent />}
      />
      <Route path={Paths.OBC} element={<OBCJumppad />} />
      <Route
        path={Paths.OBCSchedulingAssistant}
        element={<OBCSchedulingAssistantPage />}
      />
      <Route path={Paths.OBCScheduled} element={<OBCScheduledPage />} />
      <Route path={Paths.OBCChecklist} element={<OBCChecklistPage />} />
      {/* TODO: Turn on again when Pharus allows PhotoUploads */}
      {/*<Route path={Paths.OBCPhotoUpload} element={<OBCPhotoUploadPage />} />*/}
      <Route path={Paths.FirstSteps} element={<OnboardingFirstStepsPage />} />
      {guardedRoutes([
        <Route
          path={Paths.PropertySelection}
          element={<PropertySelectionPage />}
        />,
        <Route
          path={Paths.ContactChannels}
          element={<ContactChannelsPage />}
        />,
        <Route
          path={Paths.OnboardingOverview}
          element={<OnboardingOverviewPage />}
        />,
        <Route
          path={Paths.OnboardingActionRequired}
          element={<OnboardingActionRequiredPage />}
        />,
        <Route
          path={Paths.TDOrderOverview}
          element={<TDOrderOverviewPage />}
        />,
        <Route
          path={Paths.OnSiteInspectionInfo}
          element={<OnSiteInspectionInfoPage />}
        />,
        <Route
          path={Paths.OnSiteInspectionSchedulingAssistant}
          element={
            <OrtecAvailabilityProvider appointmentType={"inspection"}>
              <OnSiteInspectionSchedulingAssistantInterceptor />
            </OrtecAvailabilityProvider>
          }
        />,
        <Route
          path={Paths.OnSiteInspectionPreferredTimeslotsSet}
          element={<OnSiteInspectionPreferredTimeslotsSetPage />}
        />,
        <Route
          path={Paths.OnSiteInspectionAppointmentScheduled}
          element={<OnSiteInspectionAppointmentScheduledPage />}
        />,
        <Route
          path={Paths.OnSiteInspectionResultsPending}
          element={<OnSiteInspectionResultsPendingPage />}
        />,
        <Route
          path={Paths.InstallationKitInfo}
          element={<InstallationKitInfoPage />}
        />,
        <Route
          path={Paths.InstallationKitShippingPending}
          element={<InstallationKitShippingPendingPage />}
        />,
        <Route
          path={Paths.InstallationKitStatus}
          element={<InstallationKitStatusPage />}
        />,
        <Route
          path={Paths.InstallationPreparationPending}
          element={<InstallationPreparationPendingPage />}
        />,
        <Route
          path={Paths.InstallationInfo}
          element={<InstallationInfoPage />}
        />,
        <Route
          path={Paths.InstallationEstimatedInterval}
          element={<InstallationEstimatedIntervalPage />}
        />,
        <Route
          path={Paths.SchedulingAssistantInstallation}
          element={
            <OrtecAvailabilityProvider appointmentType={"installation"}>
              <InstallationSchedulingAssistantInterceptor />
            </OrtecAvailabilityProvider>
          }
        />,
        <Route
          path={Paths.InstallationAppointmentPreferredTimeslotsSet}
          element={<InstallationAppointmentPreferredTimeslotsSetPage />}
        />,
        <Route
          path={Paths.InstallationAppointmentScheduled}
          element={<InstallationAppointmentScheduledPage />}
        />,
        <Route
          path={Paths.InstallationPrecautions}
          element={<InstallationPrecautionsPage />}
        />,
        <Route
          path={Paths.InstallationCompleted}
          element={<InstallationCompletedPage />}
        />,
        <Route
          path={Paths.OnboardingCompleted}
          element={<OnboardingCompletedPage />}
        />,
        <Route
          path={Paths.ProgressOverview}
          element={<ProgressOverviewPage />}
        />,
        <Route path={Paths.Default} element={<DefaultRouteComponent />} />,
      ])}
      {/*
        // TODO: Add 404 Route:
        <Route>
          <NotFoundPage />
        <Route>
      */}
    </Route>
  );
};

export default createHashRouter(createRoutesFromElements(Routes()));
