import React from "react";
import Header from "layout/Header";
import Footer from "layout/Footer";
import WizardStepsForOverallProgress from "components/WizardStepsForOverallProgress";
import { Row, Col, Container } from "react-bootstrap";
import { TechemTheme } from "@techem/techem-theme";
import { useLocation, useNavigate } from "react-router-dom";
import { Paths } from "Routes";
import { DetailedEventOverview } from "components/DetailedEventOverview";
import Title from "components/Title";
import { Trans, useTranslation } from "react-i18next";
import { OrderType, StoreObject } from "reducers/onboardingInformationSlice";
import { connect } from "react-redux";
import Icon, { ICONSIZE } from "components/Icon";

import hourglassIcon from "assets/icons/png/3x/icn-hourglass@3x.png";
import SurveyView from "components/SurveyView";
import { CSSAssignBorderRadius } from "services/tools/CSSHelper";
import { SURVICATE_SURVEY_ID_VOT } from "config";

const mapStateToProps = (state: any) => {
  return {
    onboardingInfo: state.onboardingInfo,
  };
};

interface Props {
  onboardingInfo?: StoreObject;
}

const OnSiteInspectionResultsPendingPage: React.FC<Props> = ({
  onboardingInfo,
}) => {
  const navigate = useNavigate();
  const loc = useLocation();
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header>
        <WizardStepsForOverallProgress
          clickOnShowAll={() => {
            navigate(
              {
                pathname: Paths.ProgressOverview,
                search: loc.search,
              },
              {
                state: { from: loc.pathname },
              }
            );
          }}
        />
      </Header>

      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="mb-2">
              <Title
                className="mx-1"
                title={t("onSiteInspectionResultsPendingPageTitle")}
              />
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col
              xs={12}
              md={10}
              lg={8}
              style={{
                backgroundColor: TechemTheme.colors.backgroundSecondary,
                ...CSSAssignBorderRadius("8px"),
              }}
            >
              <Row className="justify-content-center">
                <Col xs="auto" className="copy p-0 mt-3 pt-2">
                  <Icon
                    iconSrc={hourglassIcon}
                    alt="hourglass"
                    size={ICONSIZE.xxxlarge}
                  />
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col
                  xs={10}
                  md={8}
                  lg={6}
                  className="copy p-0 mt-3 mb-2 pb-md-3 text-center"
                >
                  <Trans i18nKey="onSiteInspectionResultsPendingPageContent" />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col
              xs={12}
              md={10}
              lg={8}
              className="copy text-bold pt-2 pt-md-3 m-2 mt-4 mx-0"
            >
              {t("onSiteInspectionResultsPendingPageBulletSectionTitle")}
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="copy mt-1">
              <ul style={{ paddingLeft: "1.5rem", marginBottom: "0" }}>
                {Array.from({ length: 3 }, (_, i) => i + 1).map((index) => {
                  return (
                    <li key={index}>
                      <Trans
                        i18nKey={`onSiteInspectionResultsPendingPageBullet${index}`}
                        tOptions={{ skipPostProcessing: true }}
                      />
                    </li>
                  );
                })}
              </ul>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col
              xs={12}
              md={10}
              lg={8}
              className="copy text-bold my-2 mt-md-3 pt-3 pb-1"
            >
              {t("onSiteInspectionResultsPendingPageSurveySectionTitle")}
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="mt-1 mb-2 mb-md-3">
              <Row>
                <Col>
                  <SurveyView
                    surveyId={SURVICATE_SURVEY_ID_VOT}
                    tdObjectId={
                      onboardingInfo!.userData.properties[
                        onboardingInfo!.selectedPropertyIndex
                      ].orders.find(
                        (order) => order.type === OrderType.TechemDirect
                      )!.orderNumber
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="mt-md-3 pt-5">
              <DetailedEventOverview
                data={
                  onboardingInfo!.userData.properties.length > 0
                    ? onboardingInfo!.userData.properties[
                        onboardingInfo!.selectedPropertyIndex
                      ].orders
                        .find((order) => order.type === OrderType.TechemDirect)!
                        .eventHistory.filter((eventItem) => !!eventItem.data)
                    : []
                }
              />
            </Col>
          </Row>
        </Container>
      </main>

      <Footer />
    </div>
  );
};

export default connect(mapStateToProps)(OnSiteInspectionResultsPendingPage);
