import { combineReducers } from "redux";

import onboardingInformationSlice from "./onboardingInformationSlice";
import abTestingSlice from "./abTestingSlice";
import miscSlice from "./miscSlice";
import OBCInfoSlice from "./OBCSlice";

const rootReducer = combineReducers({
  onboardingInfo: onboardingInformationSlice,
  abTesting: abTestingSlice,
  misc: miscSlice,
  OBCInfo: OBCInfoSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
