import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { styled } from "baseui";

import { TechemTheme } from "@techem/techem-theme";
import phoneIcon from "assets/icons/png/3x/icn-phone-flip.png";
import Icon, { ICONSIZE } from "components/Icon";
import store from "store";
import { minBreakpoints } from "theme/mediaQueries";
import { useMediaQuery } from "tools/hooks/useMediaQuery";

// Don't change this, as display: 'flex !important' does not (!) work with inline styling for a Container component!!!
const CustomContainer = styled(Container, {
  marginTop: "auto",
  marginBottom: "auto",
  marginLeft: "0",
  marginRight: "0",
  display: "flex !important",
  flexDirection: "column",
});

function PhoneSupportNoTitleView({ t, i18n }: any) {
  const telNumberToCall = !!store.getState().OBCInfo.token
    ? t("headerPhoneSupportOBCViewNumberToCall")
    : t("headerPhoneSupportViewNumberToCall");
  const telNumberToDisplay = !!store.getState().OBCInfo.token
    ? t("headerPhoneSupportOBCViewNumberToDisplay")
    : t("headerPhoneSupportViewNumberToDisplay");

  var telHref = "tel:" + telNumberToCall;

  const isMdOrAbove = useMediaQuery(`(min-width: ${minBreakpoints.md}px)`);

  return (
    <div className="d-flex flex-row h-100 my-auto mx-0">
      <CustomContainer className="d-none d-md-block">
        <Row className="flex-row" style={{ margin: "auto -15px" }}>
          <Col
            xs={12}
            className="d-flex justify-content-center"
            style={{ flex: "inherit !important" }}
          >
            <div className="my-auto mx-0">
              <Icon
                handleClick={() => {
                  window.location.href = telHref;
                }}
                size={isMdOrAbove ? ICONSIZE.xlarge : ICONSIZE.large}
                iconSrc={phoneIcon}
                alt="phone support icon"
              />
            </div>
            <a
              href={telHref}
              className="mx-3 my-auto"
              style={{
                fontSize: "1.5rem",
                lineHeight: "1.5rem",
                fontWeight: "bold",
                textDecoration: "inherit",
                color: TechemTheme.colors.primaryA,
              }}
            >
              {telNumberToDisplay}
            </a>
          </Col>
          <Col xs={12} style={{ flex: "inherit !important" }}>
            <span className="copy" style={{ whiteSpace: "nowrap" }}>
              {t("headerPhoneSupportViewOperatingHoursNotice")}
            </span>
          </Col>
        </Row>
      </CustomContainer>
    </div>
  );
}

export default withTranslation()(PhoneSupportNoTitleView);
