import React from "react";
import { styled } from "styletron-react";
import { TechemTheme } from "@techem/techem-theme";

interface Props {
  solid?: boolean;
}

const LineWrapper = styled("div", {
  height: "calc(100% - 28px)",
  top: "32px",
  left: "0",
  zIndex: -1,
});

const Line = styled("div", {
  width: "0",
  borderLeftWidth: "2px",
  borderLeftColor: TechemTheme.colors.contentPrimary,
});

const TimelineItemLine: React.FC<Props> = ({ solid = true }) => {
  return (
    <LineWrapper className="position-absolute w-100">
      <Line
        className="mx-auto h-100"
        style={{
          borderLeftStyle: solid ? "solid" : "dashed",
        }}
      />
    </LineWrapper>
  );
};

export default TimelineItemLine;
