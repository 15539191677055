import {
  PreferredTimeSlot,
  AvailableDaySlot as AvailableDays,
} from "clients/obc/types";
import { Dispatch, SetStateAction } from "react";
import { TimeOfDay } from "reducers/onboardingInformationSlice";
import { AppointmentType } from "./OrtecAvailabilityContext";

export enum PreferredTimeSlotIndex {
  None = -1,
  FirstSlot = 0,
  SecondSlot = 1,
  ThirdSlot = 2,
}

export interface TimeData {
  from: string;
  till: string;
}

export type PreferredTimeSlotCandidate = PreferredTimeSlot | undefined;

export interface PreferredTimeSlotContextType {
  minDate: Date | undefined;
  maxDate: Date | undefined;
  unavailableDays: Date[];
  handleSetDateCandidate: (dates: {
    readonly date: Date | (Date | null | undefined)[] | null | undefined;
  }) => void;

  preferredTimeslots: PreferredTimeSlot[];
  setPreferredTimeslots: Dispatch<SetStateAction<PreferredTimeSlot[]>>;

  availableTimeSlotsDatas: AvailableDays[];

  preferredTimeslotIndex: PreferredTimeSlotIndex;
  setPreferredTimeslotIndex: Dispatch<SetStateAction<PreferredTimeSlotIndex>>;
  resetPreferredTimeslotIndex: () => void;

  preferredTimeSlotCandidate: PreferredTimeSlotCandidate;
  setPreferredTimeSlotCandidate: Dispatch<
    SetStateAction<PreferredTimeSlotCandidate>
  >;
  dateCandidate: Date | undefined;

  preferredTimeData: TimeData | undefined;
  setPreferredTimeData: Dispatch<SetStateAction<TimeData | undefined>>;

  onSiteInspectionInterval: { from: Date; to: Date } | undefined;
  installationInterval: { from: Date; to: Date } | undefined;

  filterAvailableTimeSlots: (
    timeSlots: AvailableDays[],
    dateCandidate: Date
  ) => {
    from: string;
    till: string;
    timeOfDay: TimeOfDay;
  }[];

  submitTimeslots: (
    appointmentType: AppointmentType,
    orderId: string,
    timeslots: PreferredTimeSlot[]
  ) => Promise<any>;

  numberOfApartments: number;
}
