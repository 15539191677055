import sanitizeHtml from "sanitize-html";

const PP = {
  type: "postProcessor",
  name: "Sanitizer",
  process: function (
    value: string,
    key: string,
    options: any,
    translator: any
  ) {
    // Skipping post-processing if explicitly specified, useful for cases
    // with nested inner html within translation text that needs to be part of the DOM and not be escaped.
    if (options.skipPostProcessing) {
      return value;
    }

    let clean = sanitizeHtml(value, {
      allowedTags: ["b", "i", "em", "strong", "br"],
      selfClosing: ["br", "0"],
    });

    return clean;
  },
};
export default PP;
