import { KIND } from "baseui/button";
import React, { useContext } from "react";
import { Trans } from "react-i18next";
import { TechemTheme } from "@techem/techem-theme";
import { formatTime } from "tools/dev/DateTimeFormatHelper";
import { TrackerUtil } from "tracking/TrackerUtil";
import { Button } from "@techem/ui-components";

import { PreferredTimeSlotContext } from "contexts/PreferredTimeSlotContext";
import { TimeOfDay } from "reducers/onboardingInformationSlice";
import { PreferredTimeSlot } from "clients/obc/types";

interface PreferredTimeSlotButtonProps {
  index: number;
  timeSlot: { from: string; till: string; timeOfDay: TimeOfDay };
  kind: keyof typeof KIND;
  additionalTestId?: string;
}

const PreferredTimeSlotButton: React.FC<PreferredTimeSlotButtonProps> = (
  props
) => {
  const { setPreferredTimeSlotCandidate, setPreferredTimeData } = useContext(
    PreferredTimeSlotContext
  );

  const { index, timeSlot: item, kind, additionalTestId } = props;
  const from = new Date(item.from!).toString();
  const till = new Date(item.till!).toString();
  const timeOfDay =
    item.timeOfDay === TimeOfDay.Morning
      ? "Vormittags"
      : item.timeOfDay === TimeOfDay.Afternoon
      ? "Nachmittags"
      : "Ganztags";

  return (
    <Button
      kind={kind}
      additionalStyleAsPlainCSS={{
        "font-size": "0.875rem !important",
      }}
      mainColor={TechemTheme.colors.accent}
      mainHoverColor={TechemTheme.colors.accent700}
      //mainHoverColor={}
      onClick={() => {
        setPreferredTimeData({
          from: from,
          till: till,
        });

        setPreferredTimeSlotCandidate({ ...item } as PreferredTimeSlot);
      }}
      stretched
      additionalTestId={`timeslot ${index}${
        !!additionalTestId ? ` ${additionalTestId}` : ""
      }`}
      trackClickUsingTestId={() => {
        TrackerUtil.trackBtnClick(
          `${TrackerUtil.getPageName()}_button_timeslot-${from}-${till}`,
          `button_timeslot-${from}-${till}`
        );
      }}
    >
      <Trans
        i18nKey="TimeSlotPickerPreferredTimeslotSelectionButtonText"
        values={{
          timeOfDay: timeOfDay,
          fromTime: formatTime(new Date(item.from!), true),
          toTime: formatTime(new Date(item.till!), true),
        }}
      />
    </Button>
  );
};

export default PreferredTimeSlotButton;
