import React from "react";
import Header from "layout/Header";
import Footer from "layout/Footer";
import { Row, Col, Container } from "react-bootstrap";
import { TechemTheme } from "@techem/techem-theme";
import { DetailedEventOverview } from "components/DetailedEventOverview";
import Title from "components/Title";
import { Trans, useTranslation } from "react-i18next";
import { OrderType, StoreObject } from "reducers/onboardingInformationSlice";
import { connect } from "react-redux";
import { Button } from "@techem/ui-components";
import { SIZE } from "baseui/button";
import Icon, { ICONSIZE } from "components/Icon";
import LoadingSpinner from "components/LoadingSpinner";

import checkmarkIcon from "assets/icons/png/3x/icn-check.png";
import { TrackerUtil } from "tracking/TrackerUtil";
import { CSSAssignBorderRadius } from "services/tools/CSSHelper";

const mapStateToProps = (state: any) => {
  return {
    onboardingInfo: state.onboardingInfo,
  };
};

interface Props {
  onboardingInfo?: StoreObject;
}

const OnboardingCompletedPage: React.FC<Props> = ({ onboardingInfo }) => {
  const { t } = useTranslation();

  return !!onboardingInfo?.userData.clientNumber ? (
    <div className="d-flex flex-column min-vh-100">
      <Header />

      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="mb-2">
              <Title
                className="mx-1 my-2"
                title={t("onboardingCompletedPageTitle")}
              />
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col
              xs={12}
              md={10}
              lg={8}
              style={{
                backgroundColor: TechemTheme.colors.backgroundSecondary,
                ...CSSAssignBorderRadius("8px"),
              }}
            >
              <Row className="justify-content-center">
                <Col xs="auto" className="copy p-0 mt-3 pt-2">
                  <Icon
                    iconSrc={checkmarkIcon}
                    alt="calendar"
                    size={ICONSIZE.xxxlarge}
                  />
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col xs={10} className="copy ps-0 pe-0 my-3 pb-1 text-center">
                  <Trans i18nKey="onboardingCompletedPageContent" />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col
              xs
              md={10}
              lg={8}
              className="copy text-bold pt-2 pt-md-3 m-2 mt-4 mx-0"
            >
              {t("onboardingCompletedPageBulletSectionTitle")}
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs md={10} lg={8} className="copy mt-1">
              {t("onboardingCompletedPageBullet1")}
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs md={10} lg={8} className="copy mt-1">
              <Row className="justify-content-center">
                <Col xs="auto" className="mt-4 mb-2 mb-md-3 pt-3 pt-md-2">
                  <Button
                    size={SIZE.large}
                    onClick={() => {
                      window.location.href = "https://portal.techem.de/welcome";
                    }}
                    additionalTestId="open-customer-portal"
                    trackClickUsingTestId={(buttonIdentifier) => {
                      TrackerUtil.trackBtnClick(
                        `${TrackerUtil.getPageName()}_button_${buttonIdentifier}`,
                        `button_${buttonIdentifier}`
                      );
                    }}
                  >
                    <Trans i18nKey="onboardingCompletedPageOpenCustomerPortalButtonText" />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="mt-md-3 pt-5">
              <DetailedEventOverview
                data={
                  onboardingInfo!.userData.properties.length > 0
                    ? onboardingInfo!.userData.properties[
                        onboardingInfo!.selectedPropertyIndex
                      ].orders
                        .find((order) => order.type === OrderType.TechemDirect)!
                        .eventHistory.filter((eventItem) => !!eventItem.data)
                    : []
                }
              />
            </Col>
          </Row>
        </Container>
      </main>

      <Footer />
    </div>
  ) : (
    <Container className="m-auto" style={{ transitionDuration: "350ms" }}>
      <Row className="justify-content-center">
        <Col xs="auto" className="d-flex vh-100">
          <LoadingSpinner topLevelStylingOverrides={{ margin: "auto" }} />
        </Col>
      </Row>
    </Container>
  );
};

export default connect(mapStateToProps)(OnboardingCompletedPage);
