import React from "react";
import { TFunction } from "i18next";
import { Trans } from "react-i18next";
import { EventName } from "reducers/onboardingInformationSlice";
import {
  formatDate,
  timeOfDayBoundaries,
} from "tools/dev/DateTimeFormatHelper";

interface EventContentItem {
  title: string;
  content: string | React.ReactElement;
}

export function buildEventContent(
  t: TFunction<"translation", undefined, "translation">,
  eventName: string,
  data: any
): EventContentItem | undefined {
  switch (eventName) {
    case EventName.OrderCompleted:
      return {
        title: t("detailedEventOverviewOrderCompletedTitle"),
        content: t("detailedEventOverviewOrderCompletedContent", {
          clientNumber: data.clientNumber,
        }) as string,
      };
    case EventName.PreferredOnSiteInspectionTimeslotsSet:
      return {
        title: t(
          "detailedEventOverviewPreferredOnSiteInspectionTimeslotsSetTitle"
        ),
        content: (
          <Trans
            i18nKey="detailedEventOverviewPreferredOnSiteInspectionTimeslotsSetContent"
            tOptions={{ skipPostProcessing: true }}
          >
            <>
              {data.preferredTimeslots.map((timeSlotInfo: any) => (
                <Trans
                  key={data.preferredTimeslots.indexOf(timeSlotInfo)}
                  i18nKey="detailedEventOverviewPreferredOnSiteInspectionTimeslotsSetTimeslotItem"
                  values={{
                    dateTerm: formatDate(new Date(timeSlotInfo.date)),
                    timeslotStartTime: timeOfDayBoundaries(
                      timeSlotInfo.timeOfDay
                    ).startTime,
                    timeslotEndTime: timeOfDayBoundaries(timeSlotInfo.timeOfDay)
                      .endTime,
                  }}
                ></Trans>
              ))}
            </>
          </Trans>
        ),
      };
    case EventName.OnSiteInspectionScheduled:
      return {
        title: t("detailedEventOverviewOnSiteInspectionScheduledTitle"),
        content: t("detailedEventOverviewOnSiteInspectionScheduledContent", {
          dateTerm: formatDate(new Date(data.date)),
          timeStartTerm: data.startTime,
          timeEndTerm: data.endTime,
        }) as string,
      };
    case EventName.OnSiteInspectionFollowUpRequired:
      return {
        title: t("detailedEventOverviewOnSiteInspectionFollowupRequiredTitle"),
        content: t(
          "detailedEventOverviewOnSiteInspectionFollowupRequiredContent"
        ) as string,
      };
    case EventName.OnSiteInspectionCancelled:
      return {
        title: t("detailedEventOverviewOnSiteInspectionCancelledTitle"),
        content: t(
          "detailedEventOverviewOnSiteInspectionCancelledContent"
        ) as string,
      };
    case EventName.InstallationStateRecorded:
      return {
        title: t("detailedEventOverviewInstallationStateRecordedTitle"),
        content: t(
          "detailedEventOverviewInstallationStateRecordedContent"
        ) as string,
      };
    case EventName.InstallationKitNeeded:
      return {
        title: t("detailedEventOverviewInstallationKitNeededTitle"),
        content: t(
          "detailedEventOverviewInstallationKitNeededContent"
        ) as string,
      };
    case EventName.InstallationKitRequested:
      return {
        title: t("detailedEventOverviewInstallationKitRequestedTitle"),
        content: (
          <Trans i18nKey="detailedEventOverviewInstallationKitRequestedContent" />
        ),
      };
    case EventName.InstallationKitSent:
      return {
        title: t("detailedEventOverviewInstallationKitSentTitle"),
        content: (
          <Trans i18nKey="detailedEventOverviewInstallationKitSentContent" />
        ),
      };
    case EventName.InstallationKitInstalled:
      return {
        title: t("detailedEventOverviewInstallationKitInstalledTitle"),
        content: t(
          "detailedEventOverviewInstallationKitInstalledContent"
        ) as string,
      };
    case EventName.PreferredInstallationTimeslotsSet:
      return {
        title: t("detailedEventOverviewPreferredTimeslotsSetTitle"),
        content: (
          <Trans
            i18nKey="detailedEventOverviewPreferredTimeslotsSetContent"
            tOptions={{ skipPostProcessing: true }}
          >
            <>
              {data.preferredTimeslots.map((timeSlotInfo: any) => (
                <Trans
                  key={data.preferredTimeslots.indexOf(timeSlotInfo)}
                  i18nKey="detailedEventOverviewPreferredTimeslotsSetTimeslotItem"
                  values={{
                    dateTerm: formatDate(new Date(timeSlotInfo.date)),
                    timeslotStartTime: timeOfDayBoundaries(
                      timeSlotInfo.timeOfDay
                    ).startTime,
                    timeslotEndTime: timeOfDayBoundaries(timeSlotInfo.timeOfDay)
                      .endTime,
                  }}
                ></Trans>
              ))}
            </>
          </Trans>
        ),
      };
    case EventName.InstallationTimeslotScheduled:
      return {
        title: t("detailedEventOverviewInstallationTimeslotScheduledTitle"),
        content: t(
          "detailedEventOverviewInstallationTimeslotScheduledContent",
          {
            dateTerm: formatDate(new Date(data.date)),
            timeStartTerm: data.startTime,
            timeEndTerm: data.endTime,
          }
        ) as string,
      };
    case EventName.InstallationFollowUpRequired:
      return {
        title: t("detailedEventOverviewInstallationFollowUpRequiredTitle"),
        content: t(
          "detailedEventOverviewInstallationFollowUpRequiredContent"
        ) as string,
      };
    case EventName.InstallationTimeslotCancelled:
      return {
        title: t("detailedEventOverviewInstallationTimeslotCancelledTitle"),
        content: t(
          "detailedEventOverviewInstallationTimeslotCancelledContent"
        ) as string,
      };
    case EventName.InstallationCompleted:
      return {
        title: t("detailedEventOverviewInstallationCompletedTitle"),
        content: t(
          "detailedEventOverviewInstallationCompletedContent"
        ) as string,
      };
    case EventName.OnboardingCompleted:
      return {
        title: t("detailedEventOverviewOnboardingCompletedTitle"),
        content: t("detailedEventOverviewOnboardingCompletedContent") as string,
      };

    // Special cases where we don't want an explicit entry in the event history list
    case EventName.PreferredOnSiteInspectionTimeslotsRequired:
    case EventName.OnboardingAccessGranted:
    case EventName.InstallationIntervalSet:
    case EventName.PreferredInstallationTimeslotsRequired:
    default:
      return undefined;
  }
}
