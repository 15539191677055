import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Title from "../../components/Title";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";

interface ErrorPageProps {
  title: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ title, children }) => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />

      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} className="text-center">
              <Title
                className="mx-1 text-center justify-content-center"
                title={title}
              />
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} className="text-center">
              {children}
            </Col>
          </Row>
        </Container>
      </main>

      <Footer />
    </div>
  );
};

export { ErrorPage };
