import React from "react";
import Header from "layout/Header";
import Footer from "layout/Footer";
import WizardStepsForOverallProgress from "components/WizardStepsForOverallProgress";
import { Row, Col, Container } from "react-bootstrap";
import { TechemTheme } from "@techem/techem-theme";
import { useLocation, useNavigate } from "react-router-dom";
import { Paths } from "Routes";
import { DetailedEventOverview } from "components/DetailedEventOverview";
import Title from "components/Title";
import { Trans, useTranslation } from "react-i18next";
import { OrderType, StoreObject } from "reducers/onboardingInformationSlice";
import { connect } from "react-redux";
import { Button } from "@techem/ui-components";
import { SIZE } from "baseui/button";
import { TrackerUtil } from "tracking/TrackerUtil";
import Icon, { ICONSIZE } from "components/Icon";
import housePersonReturn from "assets/icons/png/3x/icn-house-person-return.png";
import { CSSAssignBorderRadius } from "services/tools/CSSHelper";

const mapStateToProps = (state: any) => {
  return {
    onboardingInfo: state.onboardingInfo,
  };
};

interface Props {
  onboardingInfo?: StoreObject;
}

const OnSiteInspectionInfoPage: React.FC<Props> = ({ onboardingInfo }) => {
  const navigate = useNavigate();
  const loc = useLocation();
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header>
        <WizardStepsForOverallProgress
          clickOnShowAll={() => {
            navigate(
              {
                pathname: Paths.ProgressOverview,
                search: loc.search,
              },
              {
                state: { from: loc.pathname },
              }
            );
          }}
        />
      </Header>

      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="mb-0">
              <Title
                className="mx-1 pb-2 pb-md-3"
                title={t("onSiteInspectionInfoPageTitle")}
              />

              <Row className="justify-content-center pt-1">
                <Col
                  style={{
                    backgroundColor: TechemTheme.colors.backgroundSecondary,
                    ...CSSAssignBorderRadius("8px"),
                  }}
                >
                  <Row className="justify-content-center">
                    <Col xs="auto" className="copy p-0 mt-3 pt-2">
                      <Icon
                        size={ICONSIZE.xxxlarge}
                        iconSrc={housePersonReturn}
                        alt="clipboard-icon"
                      />
                    </Col>
                  </Row>

                  <Row className="justify-content-center">
                    <Col
                      xs={10}
                      className="copy p-0 pb-3 mb-2 mt-3 text-center"
                    >
                      <Trans
                        i18nKey="onSiteInspectionInfoPageContent"
                        tOptions={{ skipPostProcessing: true }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="mt-4 pt-2">
              <h4 className="copy-l text-bold">
                <Trans i18nKey="onSiteInspectionInfoPageContentTitle" />
              </h4>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="copy mx-3">
              <ul style={{ paddingLeft: "1.5rem" }}>
                {Array.from({ length: 3 }, (_, i) => i + 1).map((index) => {
                  return (
                    <li key={index} style={{}}>
                      {t(`onSiteInspectionInfoPageContentBullet${index}`)}
                    </li>
                  );
                })}
              </ul>
            </Col>
          </Row>

          <Row className="justify-content-center pt-3">
            <Col
              xs={12}
              md={10}
              lg={8}
              style={{
                backgroundColor: TechemTheme.colors.backgroundSecondary,
                ...CSSAssignBorderRadius("8px"),
              }}
            >
              <Row className="justify-content-center">
                <Col
                  xs={10}
                  className="copy text-bold text-center pt-2 mt-4 pb-1"
                >
                  <span style={{ fontSize: "1.5rem", lineHeight: "2rem" }}>
                    {t("onSiteInspectionInfoPageAcknowledgementSectionTitle")}
                  </span>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col xs={10} className="copy p-0 my-2 text-center">
                  <Trans
                    i18nKey="onSiteInspectionInfoPageAcknowledgementSectionDescription"
                    tOptions={{ skipPostProcessing: true }}
                  />
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col xs="auto" className="my-2 mb-4 pb-2">
                  <Button
                    size={SIZE.large}
                    onClick={() => {
                      navigate({
                        pathname: Paths.OnSiteInspectionSchedulingAssistant,
                        search: loc.search,
                      });
                    }}
                    additionalTestId="proceed-to-appointment-selection"
                    trackClickUsingTestId={(buttonIdentifier) => {
                      TrackerUtil.trackBtnClick(
                        `${TrackerUtil.getPageName()}_button_${buttonIdentifier}`,
                        `button_${buttonIdentifier}`
                      );
                    }}
                  >
                    {t(
                      "onSiteInspectionInfoPageProceedToAppointmentSelectionButtonText"
                    )}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="mt-md-3 pt-4 px-0">
              <DetailedEventOverview
                data={
                  onboardingInfo!.userData.properties.length > 0
                    ? onboardingInfo!.userData.properties[
                        onboardingInfo!.selectedPropertyIndex
                      ].orders
                        .find((order) => order.type === OrderType.TechemDirect)!
                        .eventHistory.filter((eventItem) => !!eventItem.data)
                    : []
                }
              />
            </Col>
          </Row>
        </Container>
      </main>

      <Footer />
    </div>
  );
};

export default connect(mapStateToProps)(OnSiteInspectionInfoPage);
