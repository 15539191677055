import Icon, { ICONSIZE } from "components/Icon";
import React from "react";
import { Row, Col } from "react-bootstrap";
import { TechemTheme } from "@techem/techem-theme";
import TimelineItemLine from "./TimelineItemLine";
// icons
import circleCheckIcon from "assets/icons/png/3x/circle-check@3x.png";
import circleIcon from "assets/icons/png/3x/circle@3x.png";
import checkmarkOptionalEmptyIcon from "assets/icons/png/3x/icn-circle-dashed.png";

interface TimelineItemProps {
  checked?: boolean;
  last?: boolean;
  transparent?: boolean;
  isCurrent?: boolean;
  hidden?: boolean;
  additionalTestId: string;
}

export const TimelineItem: React.FC<
  React.PropsWithChildren<TimelineItemProps>
> = ({
  checked = false,
  last = false,
  transparent = false,
  isCurrent = false,
  hidden = false,
  additionalTestId,
  children,
}) => {
  return (
    <Row className={hidden ? "d-none" : "d-flex"} data-gi={additionalTestId}>
      <Col xs="auto" style={{ zIndex: 0 }} className="pb-2 position-relative">
        {!last && <TimelineItemLine solid={!transparent} />}
        <Icon
          iconSrc={
            transparent
              ? checkmarkOptionalEmptyIcon
              : checked
              ? circleCheckIcon
              : circleIcon
          }
          alt="list icon"
          size={ICONSIZE.large}
        />
        {isCurrent && (
          <span
            className="position-absolute"
            style={{
              fontSize: "1.75rem",
              left: "40px",
              top: "6px",
              lineHeight: "24px",
              color: TechemTheme.colors.contentPrimary,
            }}
          >
            →
          </span>
        )}
      </Col>
      <Col
        xs
        className="pt-1 pb-2 pb-md-4"
        style={{ opacity: transparent ? "0.6" : "1" }}
      >
        {children}
      </Col>
    </Row>
  );
};
