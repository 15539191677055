// Functions to work with etracker.de
import { ETRACKER_SECURE_CODE } from "config";
import { ABTesting } from "reducers/abTestingSlice";

export const isEtrackerLoaded = (): boolean => {
  return typeof window._etracker === "object";
};

// The etracker script ID must never be changed, because it is used by
// etracker to look up attributes from the script tag
const etrackerScriptId = "_etLoader";

const etrackerDownloadUrl = "//static.etracker.com/code/e.js";

/**
 * Loads the etracker script into the current DOM.
 * Returns a promise which resolves to {@code true} if the script needs to be initialized after.
 */
export function loadEtracker(
  respectDnt: boolean,
  globalPageName: string,
  abTestingObject: ABTesting,
  secureCode?: string
): Promise<boolean> {
  if (!secureCode) {
    // don't try to load it if no secureCode got provided
    return Promise.resolve(false);
  }

  if (document.getElementById(etrackerScriptId)) {
    console.warn("etracker script is already attached to DOM");
    return Promise.resolve(false);
  }

  return new Promise((resolve, reject) => {
    window.et_pagename = globalPageName;

    const script = document.createElement("script");
    script.setAttribute("data-block-cookies", "true");
    script.setAttribute("data-respect-dnt", respectDnt ? "true" : "false");
    script.setAttribute("data-secure-code", secureCode);
    script.id = etrackerScriptId;
    script.async = true;
    script.src = etrackerDownloadUrl;
    script.onload = function () {
      resolve(true);
    };
    script.onerror = function () {
      reject("failed to load etracker script");
    };
    document.head.appendChild(script);
  });
}

export const trackEvent = (
  object: string,
  category: string,
  action: string,
  type: string,
  value?: string
) => {
  if (!ETRACKER_SECURE_CODE) {
    console.info("SECURE_CODE is not set. trackEvent was triggered", {
      object,
      category,
      action,
      type,
      value,
    });
  }
  if (!isEtrackerLoaded()) {
    return;
  }

  window._etracker.sendEvent(
    new et_UserDefinedEvent(object, category, action, type, value)
  );
};

export const trackPage = (pageName: string) => {
  if (!ETRACKER_SECURE_CODE) {
    console.info("SECURE_CODE is not set. trackPage was triggered", {
      pageName,
    });
  }
  if (!isEtrackerLoaded()) {
    return;
  }

  window.et_eC_Wrapper({
    et_et: ETRACKER_SECURE_CODE,
    et_pagename: pageName,
  });
};
