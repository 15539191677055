import React, { PropsWithChildren } from "react";
import { styled } from "styletron-react";
import { Col, Row } from "react-bootstrap";
import { Trans } from "react-i18next";

import { TechemTheme } from "@techem/techem-theme";

import { TrackerUtil } from "tracking/TrackerUtil";
import { CSSAssignBorderRadius } from "services/tools/CSSHelper";

interface ContainerProps {
  id?: string;
}
export const WizardStepsContainer: React.FC<
  PropsWithChildren<ContainerProps>
> = ({ id, children }) => {
  return (
    <Row id={id} className="justify-content-center">
      <Col xs={11} lg={10} style={{ position: "relative" }}>
        <Row className="justify-content-center">
          <Col md={7} lg={6} style={{ position: "relative" }}>
            {children}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const ItemsRow = styled(Row, {
  height: "3px",
  marginTop: "2px",
  marginBottom: "2px",
  paddingTop: "40px",
  "@media (max-width: 767px)": {
    paddingTop: "0",
    paddingBottom: "0",
  },
});

const Step = styled("div", {
  "@media (min-width: 376px)": {
    minWidth: "76px",
  },
  height: "3px",
  ...CSSAssignBorderRadius("3px"),
  backgroundColor: TechemTheme.colors.inputBorder,
});

// TODO: Fix in future styletron updates where type inference works correctly.
// Replaced styletron's withStyle implementation as we get this error when using these components:
// JSX element type 'FilledStep' does not have any construct or call signatures. ts(2604)
//const FilledStep = withStyle(Step, {
//  backgroundColor: TechemTheme.colors.primary,
//});
const FilledStep = () => {
  return <Step $style={{ backgroundColor: TechemTheme.colors.primary }} />;
};

interface StepsProps {
  currentStep: number;
  totalSteps: number;
  clickOnShowAll?: () => void;
}
export const WizardSteps: React.FC<StepsProps> = ({
  currentStep,
  totalSteps,
  clickOnShowAll,
}) => {
  return (
    <>
      <ItemsRow>
        {[...Array(totalSteps)].map((_, i) => (
          <Col key={i} className="px-1">
            {i < currentStep ? <FilledStep /> : <Step className="w-auto" />}
          </Col>
        ))}
      </ItemsRow>

      {clickOnShowAll && (
        <span
          className="d-none d-md-block position-absolute copy"
          style={{
            left: "calc(100% + 30px)",
            top: "24px",
            color: TechemTheme.colors.primary,
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => {
            TrackerUtil.trackLinkClick(
              `${TrackerUtil.getPageName()}_link_process-overview`,
              "link_process-overview"
            );
            clickOnShowAll();
          }}
          data-gi="show-all-steps"
        >
          <Trans i18nKey="wizardStepsProcessOverviewButtonText" />
        </span>
      )}
    </>
  );
};

interface StepsDescriptionProps {
  currentStep: number;
  totalSteps: number;
  clickOnShowAll?: () => void;
}
export const WizardStepsDescription: React.FC<StepsDescriptionProps> = ({
  currentStep,
  totalSteps,
  clickOnShowAll,
}) => {
  return (
    <Row className="justify-content-center">
      <Col xs={12} className="d-flex position-relative">
        <div className="copy my-2 my-md-3 m-auto">
          <span>
            <Trans
              i18nKey="wizardStepsProgressDescription"
              values={{ currentStep: currentStep, totalSteps: totalSteps }}
            />
          </span>

          {clickOnShowAll && (
            <span
              className="position-absolute d-md-none"
              style={{
                marginLeft: "20px",
                color: TechemTheme.colors.primary,
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                TrackerUtil.trackLinkClick(
                  `${TrackerUtil.getPageName()}_link_process-overview`,
                  "link_process-overview"
                );
                clickOnShowAll();
              }}
              data-gi="show-all-steps"
            >
              <Trans i18nKey="wizardStepsShowAllButtonText" />
            </span>
          )}
        </div>
      </Col>
    </Row>
  );
};
