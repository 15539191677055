import React from "react";
import { useStyletron } from "baseui";
import Header from "layout/Header";
import Footer from "layout/Footer";
import { Row, Col, Container } from "react-bootstrap";
import { TechemTheme } from "@techem/techem-theme";
import { useLocation, useNavigate } from "react-router-dom";
import { Paths } from "Routes";
import { DetailedEventOverview } from "components/DetailedEventOverview";
import Title from "components/Title";
import { Trans, useTranslation } from "react-i18next";
import { OrderType, StoreObject } from "reducers/onboardingInformationSlice";
import { connect } from "react-redux";
import Icon, { ICONSIZE } from "components/Icon";
import packageDeliveryIcon from "assets/icons/png/3x/icn-truck-ramp-box@3x.png";
import { TrackerUtil } from "tracking/TrackerUtil";
import { CSSAssignBorderRadius } from "services/tools/CSSHelper";

const mapStateToProps = (state: any) => {
  return {
    onboardingInfo: state.onboardingInfo,
  };
};

interface Props {
  onboardingInfo?: StoreObject;
}

const InstallationKitShippingPendingPage: React.FC<Props> = ({
  onboardingInfo,
}) => {
  const [css] = useStyletron();
  const navigate = useNavigate();
  const loc = useLocation();
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header ignoreSpacing></Header>
      <Container>
        <Row className="justify-content-center mb-4 mb-md-3 pt-0 pt-md-3">
          <Col
            className="d-flex justify-content-center copy"
            style={{
              color: TechemTheme.colors.primary,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            <span
              onClick={() => {
                TrackerUtil.trackLinkClick(
                  `${TrackerUtil.getPageName()}_link_process-overview`,
                  "link_process-overview"
                );

                navigate(
                  {
                    pathname: Paths.ProgressOverview,
                    search: loc.search,
                  },
                  {
                    state: { from: loc.pathname },
                  }
                );
              }}
              data-gi="show-all-steps"
            >
              <Trans i18nKey="wizardStepsProcessOverviewButtonText" />
            </span>
          </Col>
        </Row>
      </Container>

      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <Row className="justify-content-center text-center mt-md-3 pt-md-3">
            <Col xs={12} md={10} lg={8} className="pb-3 mb-4">
              <Title
                className="mx-1"
                title={t("installationKitShippingPendingPageTitle")}
              />
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col
              xs={12}
              md={10}
              lg={8}
              style={{
                backgroundColor: TechemTheme.colors.backgroundSecondary,
                ...CSSAssignBorderRadius("8px"),
              }}
            >
              <Row className="justify-content-center">
                <Col xs="auto" className="copy p-0 mt-3 pt-2">
                  <Icon
                    size={ICONSIZE.xxxlarge}
                    iconSrc={packageDeliveryIcon}
                    alt="package delivery"
                  />
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col
                  xs={12}
                  md={10}
                  lg={8}
                  className={`copy p-0 mt-3 mb-4 pb-3 text-center ${css({
                    maxWidth: "720px",
                  })}`}
                >
                  <Trans i18nKey="installationKitShippingPendingPageContent" />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8}>
              <Row className="justify-content-center">
                <Col
                  xs={10}
                  className="copy p-0 pt-2 pt-md-3 m-3 mt-4 text-bold"
                >
                  <span className="copy fs-5">
                    <Trans i18nKey="installationKitShippingPendingPageBulletSectionTitle" />
                  </span>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col xs={10} className="copy p-0 mx-3">
                  <ul className="mb-0">
                    <li>
                      <Trans i18nKey="installationKitShippingPendingPageBulletSectionBullet1" />
                    </li>
                    <li>
                      <Trans i18nKey="installationKitShippingPendingPageBulletSectionBullet2" />
                    </li>
                    <li>
                      <Trans i18nKey="installationKitShippingPendingPageBulletSectionBullet3" />
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} className="mt-md-3 pt-5">
              <DetailedEventOverview
                data={
                  onboardingInfo!.userData.properties.length > 0
                    ? onboardingInfo!.userData.properties[
                        onboardingInfo!.selectedPropertyIndex
                      ].orders
                        .find((order) => order.type === OrderType.TechemDirect)!
                        .eventHistory.filter((eventItem) => !!eventItem.data)
                    : []
                }
              />
            </Col>
          </Row>
        </Container>
      </main>

      <Footer />
    </div>
  );
};

export default connect(mapStateToProps)(InstallationKitShippingPendingPage);
