import { AppointmentClient } from "clients";
import { TimeSlot, TimeslotDataItem } from "clients/obc/types";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { PublicHolidays } from "services/tools/PublicHolidayProvider";
import { AppointmentType } from "./OrtecAvailabilityContext";

interface OrtecTimeSlotProviderProps {
  availableOrtecTimeSlots: TimeSlot[];
}

interface OrtecContextType {
  // Slots
  availableTimeSlots: TimeslotDataItem[];
  unavailableDays: Date[];
  // period
  minDate: Date;
  maxDate: Date;

  // selected date
  dateCandidate: Date | undefined;
  handleSetDateCandidate: (dates: {
    readonly date: Date | (Date | null | undefined)[] | null | undefined;
  }) => void;
  // selected timeslot
  timeSlotCandidate: TimeSlot | undefined;
  setTimeSlotCandidate: Dispatch<SetStateAction<TimeSlot | undefined>>;

  submitTimeslot: (
    appointmentType: AppointmentType,
    orderId: string,
    timeslot: TimeSlot
  ) => Promise<any>;
}

export const OrtecTimeSlotContext = createContext({} as OrtecContextType);

const OrtecTimeSlotProvider: React.FC<OrtecTimeSlotProviderProps> = ({
  availableOrtecTimeSlots,
  children,
}) => {
  const [minDate, setMinDate] = useState<Date>(new Date());
  const [maxDate, setMaxDate] = useState<Date>(new Date());
  const [unavailableDays, setUnavailableDays] = useState<Date[]>([]);
  // all available timeslots
  const [availableTimeSlots, setAvailableTimeSlots] = useState<
    TimeslotDataItem[]
  >([]);
  // chosen date in calendar
  const [dateCandidate, setDateCandidate] = useState<Date | undefined>(
    undefined
  );
  // chosen timeslot
  const [timeSlotCandidate, setTimeSlotCandidate] = useState<
    TimeSlot | undefined
  >(undefined);

  const handleSetDateCandidate = (dates: {
    readonly date: Date | (Date | null | undefined)[] | null | undefined;
  }) => {
    setDateCandidate(
      !!dates.date
        ? ((Array.isArray(dates.date) ? dates.date[0] : dates.date) as Date)
        : undefined
    );
    setTimeSlotCandidate(undefined);
  };

  const submitTimeslot = (
    appointmentType: AppointmentType,
    orderId: string,
    timeslot: TimeSlot
  ) => {
    if (appointmentType === "installation")
      return AppointmentClient.setInstallationOrtecTimeSlot(orderId, timeslot);

    return AppointmentClient.setOnSiteInspectionOrtecTimeSlot(
      orderId,
      timeslot
    );
  };

  const contextData = {
    availableTimeSlots,
    unavailableDays,
    minDate,
    maxDate,
    dateCandidate,
    timeSlotCandidate,
    setDateCandidate,
    handleSetDateCandidate,
    setTimeSlotCandidate,
    submitTimeslot,
  };

  useEffect(() => {
    let newAvailableTimeslots: TimeslotDataItem[] = [];
    let newUnavailableDays: Date[] = [];
    let newMinDate = new Date(new Date().setHours(0, 0, 0, 0));
    setMinDate(newMinDate);

    let newMaxDate = new Date();
    setMaxDate(newMaxDate);

    let availableSlots = availableOrtecTimeSlots.sort(
      (a: TimeSlot, b: TimeSlot) => {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(a.from).valueOf() - new Date(b.from).valueOf();
      }
    );

    // build the timeslot array only if there are raw timeslots available
    if (availableSlots.length > 0) {
      newMaxDate = new Date(availableSlots[availableSlots.length - 1].from);
      newMaxDate.setHours(0, 0, 0, 0);
      setMaxDate(newMaxDate);

      for (
        let i = new Date(newMinDate);
        i.valueOf() <= newMaxDate.valueOf();
        i = new Date(new Date(i).setDate(i.getDate() + 1))
      ) {
        let timeSlotsForDay = availableSlots.filter((timeSlot) => {
          let from = new Date(timeSlot.from);
          return (
            from.getFullYear() === i.getFullYear() &&
            from.getMonth() === i.getMonth() &&
            from.getDate() === i.getDate()
          );
        });

        if (timeSlotsForDay.length > 0) {
          newAvailableTimeslots = [
            ...newAvailableTimeslots,
            { date: i, availableTimeslots: timeSlotsForDay },
          ];
        } else {
          newUnavailableDays = [...newUnavailableDays, i];
          setUnavailableDays([...newUnavailableDays, ...PublicHolidays]);
        }
      }
    }

    setAvailableTimeSlots(newAvailableTimeslots);
  }, [availableOrtecTimeSlots]);

  return (
    <OrtecTimeSlotContext.Provider value={contextData}>
      {children}
    </OrtecTimeSlotContext.Provider>
  );
};

export default OrtecTimeSlotProvider;
