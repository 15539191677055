import React from "react";

interface MailToProps {
  email: string;
  label: string;
  subject?: string;
}

const MailTo: React.FC<MailToProps> = ({ email, label, subject }) => {
  return (
    <a
      id="mailto"
      href={`mailto:${email}${subject ? "?subject=" + subject : ""}`}
    >
      {label}
    </a>
  );
};

export default MailTo;
