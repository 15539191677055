import { KIND, SIZE } from "baseui/button";
import Icon, { ICONSIZE } from "components/Icon";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { TechemTheme } from "@techem/techem-theme";
import { minBreakpoints } from "theme/mediaQueries";
import { useMediaQuery } from "tools/hooks/useMediaQuery";
import { TrackerUtil } from "tracking/TrackerUtil";
import { Button } from "@techem/ui-components";
import calendarIcon from "assets/icons/png/3x/icn-calendar.png";
import editIcon from "assets/icons/png/3x/icn-edit@3x-rot.png";
import { TimeOfDay } from "reducers/onboardingInformationSlice";
import { formatDate } from "tools/dev/DateTimeFormatHelper";
import { PreferredTimeSlot } from "clients/obc/types";

interface Props {
  id: number;
  preferredTimeSlot?: PreferredTimeSlot;
  disabled: boolean;
  isLast: boolean;
  onSetPreferredTimeslotButtonClick: (id: number) => void;
}

const PreferredTimeSlotPickerElement: React.FC<Props> = ({
  preferredTimeSlot,
  disabled,
  isLast,
  id,
  onSetPreferredTimeslotButtonClick,
}) => {
  const isMdOrAbove = useMediaQuery(`(min-width: ${minBreakpoints.md}px)`);
  const { t } = useTranslation();

  const formatDateString = (date: Date) => {
    return formatDate(date).replace(",", "");
  };

  const getTimeslotOptionTerm = (selectedOption: TimeOfDay) => {
    switch (selectedOption) {
      case TimeOfDay.AllDay:
        return t(
          "schedulingAssistantInstallationPageTimeslotSelectionModalOptionAllDay"
        );
      case TimeOfDay.Morning:
        return t(
          "schedulingAssistantInstallationPageTimeslotSelectionModalOptionMorning"
        );
      default:
        return t(
          "schedulingAssistantInstallationPageTimeslotSelectionModalOptionAfternoon"
        );
    }
  };

  return (
    <>
      {isMdOrAbove && (
        <Row className="justify-content-center">
          <Col xs="auto" className="copy p-0 mt-3 pt-3">
            <Icon
              size={ICONSIZE.xxxlarge}
              iconSrc={calendarIcon}
              alt="calendar"
            />
          </Col>
        </Row>
      )}

      <Row
        className={`mx-1 mt-2${isMdOrAbove ? " justify-content-center" : ""}`}
      >
        <Col xs="auto" className={`p-0 text-bold copy`}>
          <Trans i18nKey="TimeSlotPickerPreferredTimeslotTitle" /> {id + 1}
        </Col>
      </Row>

      <Row className="justify-content-center">
        {!!preferredTimeSlot?.date && !!preferredTimeSlot?.timeOfDay ? (
          <Col
            xs={11}
            className="my-3 py-2"
            style={{ backgroundColor: "white", borderRadius: "5px" }}
          >
            <Row
              style={{ marginTop: "3px", marginBottom: "3px" }}
              data-gi={`selected-preferred-timeslot-${
                id === 0 ? "first" : id === 1 ? "second" : "third"
              }`}
            >
              <Col xs className={`copy${isMdOrAbove ? " text-center" : ""}`}>
                <span>{formatDateString(preferredTimeSlot.date!)}</span>
                {isMdOrAbove ? <br /> : <span>{" | "}</span>}
                <span>{`${getTimeslotOptionTerm(
                  preferredTimeSlot.timeOfDay!
                )}`}</span>
              </Col>
              <Col xs="auto">
                <Icon
                  iconSrc={editIcon}
                  alt="edit"
                  handleClick={() => {
                    onSetPreferredTimeslotButtonClick(id);
                  }}
                  size={ICONSIZE.default}
                  centered
                  additionalTestId={`edit-preferred-timeslot-${
                    id === 0 ? "first" : id === 1 ? "second" : "third"
                  }`}
                ></Icon>
              </Col>
            </Row>
          </Col>
        ) : (
          <Col
            xs={12}
            md="auto"
            className="mt-2 mt-md-0 mb-2 py-3 px-md-0 text-center"
          >
            <Button
              kind={isLast ? KIND.secondary : KIND.primary}
              disabled={disabled}
              size={SIZE.large}
              onClick={() => {
                onSetPreferredTimeslotButtonClick(id);
              }}
              additionalTestId={`set-preferred-timeslots-${
                id === 0 ? "first" : id === 1 ? "second" : "third"
              }`}
              trackClickUsingTestId={(buttonIdentifier) => {
                TrackerUtil.trackBtnClick(
                  `${TrackerUtil.getPageName()}_button_${buttonIdentifier}`,
                  `button_${buttonIdentifier}`
                );
              }}
              stretched
            >
              <Trans i18nKey="TimeSlotPickerPreferredTimeslotButtonText" />
            </Button>
            {isLast ? <div className="mt-2">*optional</div> : ""}
          </Col>
        )}
      </Row>

      {isMdOrAbove && !isLast && (
        <div
          className="position-absolute"
          style={{
            width: "1px",
            backgroundColor: TechemTheme.colors.primaryB,
            height: "calc(100% -  30px)",
            marginTop: "15px",
            marginBottom: "15px",
            right: 0,
            top: 0,
          }}
        ></div>
      )}
    </>
  );
};

export default PreferredTimeSlotPickerElement;
