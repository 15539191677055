import React from "react";
import Header from "layout/Header";
import Footer from "layout/Footer";
import { Row, Col, Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import TitleWithPrevPageLink from "components/TitleWithPrevPageLink";
import { Trans, useTranslation } from "react-i18next";
import { OrderType, StoreObject } from "reducers/onboardingInformationSlice";
import { connect } from "react-redux";
import LoadingSpinner from "components/LoadingSpinner";
import YoutubeEmbeddedVideo from "components/YoutubeEmbeddedVideo";

import { SIZE } from "baseui/button";
import { Button } from "@techem/ui-components";
import { TrackerUtil } from "tracking/TrackerUtil";

import { Paths } from "Routes";
import { minBreakpoints } from "theme/mediaQueries";
import { useMediaQuery } from "tools/hooks/useMediaQuery";
import { OnboardingInformationClient } from "clients";

const mapStateToProps = (state: any) => {
  return {
    onboardingInfo: state.onboardingInfo,
  };
};

interface Props {
  onboardingInfo?: StoreObject;
}

const InstallationPrecautionsPage: React.FC<Props> = ({ onboardingInfo }) => {
  const navigate = useNavigate();
  const loc = useLocation();
  const { t } = useTranslation();
  const isMdOrAbove = useMediaQuery(`(min-width: ${minBreakpoints.md}px)`);

  const onDownloadTenantAnnouncementClick = () => {
    OnboardingInformationClient.getTenantInstallationInfoDocument(
      onboardingInfo!.userData.properties[
        onboardingInfo!.selectedPropertyIndex
      ].orders.find((o) => o.type === OrderType.TechemDirect)!.orderNumber
    );
  };

  return !!onboardingInfo?.userData.clientNumber ? (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <TitleWithPrevPageLink
            onPrevClick={() => {
              // Let ApplicationInterceptor determine where to go,
              // as a simple browser back action could also direct the user out of the application.
              navigate(
                {
                  pathname: Paths.Default,
                  search: loc.search,
                },
                {
                  state: { from: loc.pathname },
                }
              );
            }}
            title={t("installationPrecautionsPageTitle")}
          />

          <Row className="justify-content-center mt-3">
            <Col xs style={{ maxWidth: "524px" }}>
              <YoutubeEmbeddedVideo embedId="Wfy1XCuBRFc" />
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col
              xs
              md={10}
              lg={8}
              className="copy text-bold pt-2 pt-md-3 m-2 mt-4 mx-0"
            >
              {t("installationPrecautionsPagePrecautionsSectionTitle")}
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs md={10} lg={8} className="copy mt-1">
              <ul style={{ paddingLeft: "1.5rem" }} className="mb-0">
                {Array.from({ length: 3 }, (_, i) => i + 1).map((index) => {
                  return (
                    <li key={index}>
                      {t(
                        `installationPrecautionsPagePrecautionsSectionBullet${index}`
                      )}
                    </li>
                  );
                })}
              </ul>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs md={10} lg={8} className="copy text-bold my-2 pt-3 pb-1">
              {t("installationPrecautionsPageToolsSectionTitle")}
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs md={10} lg={8} className="copy mt-1">
              <Row className={isMdOrAbove ? "" : "justify-content-center"}>
                <Col xs="auto" className="pb-3">
                  <Button
                    size={SIZE.large}
                    onClick={() => {
                      onDownloadTenantAnnouncementClick();
                    }}
                    additionalTestId="download-tenant-announcement"
                    trackClickUsingTestId={(buttonIdentifier) => {
                      TrackerUtil.trackBtnClick(
                        `${TrackerUtil.getPageName()}_button_${buttonIdentifier}`,
                        `button_${buttonIdentifier}`
                      );
                    }}
                  >
                    <Trans i18nKey="installationPrecautionsPageToolsDownloadTenantAnnouncementButtonText" />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </div>
  ) : (
    <Container className="m-auto" style={{ transitionDuration: "350ms" }}>
      <Row className="justify-content-center">
        <Col xs="auto" className="d-flex vh-100">
          <LoadingSpinner topLevelStylingOverrides={{ margin: "auto" }} />
        </Col>
      </Row>
    </Container>
  );
};

export default connect(mapStateToProps)(InstallationPrecautionsPage);
