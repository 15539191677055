import React from "react";
import Header from "layout/Header";
import Footer from "layout/Footer";
import { Row, Col, Container } from "react-bootstrap";
import { TechemTheme } from "@techem/techem-theme";
import { useLocation, useNavigate } from "react-router-dom";
import { Paths } from "Routes";
import { Trans, useTranslation } from "react-i18next";
import { StoreObject } from "reducers/onboardingInformationSlice";
import { connect } from "react-redux";
import LoadingSpinner from "components/LoadingSpinner";
import { TrackerUtil } from "tracking/TrackerUtil";
import OrderOverview from "components/OrderOverview";
import Title from "components/Title";

const mapStateToProps = (state: any) => {
  return {
    onboardingInfo: state.onboardingInfo,
  };
};

interface Props {
  onboardingInfo?: StoreObject;
}

const OnboardingOverviewPage: React.FC<Props> = ({ onboardingInfo }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loc = useLocation();

  return !!onboardingInfo?.userData.clientNumber ? (
    <div className="d-flex flex-column min-vh-100">
      <Header ignoreSpacing={true}></Header>
      <Container>
        <Row className="justify-content-center mb-4 mb-md-3 pt-0 pt-md-3">
          <Col
            className="d-flex justify-content-center copy"
            style={{
              color: TechemTheme.colors.primary,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            <span
              onClick={() => {
                TrackerUtil.trackLinkClick(
                  `${TrackerUtil.getPageName()}_link_process-overview`,
                  "link_process-overview"
                );

                navigate(
                  {
                    pathname: Paths.ProgressOverview,
                    search: loc.search,
                  },
                  {
                    state: { from: loc.pathname },
                  }
                );
              }}
              data-gi="show-all-steps"
            >
              <Trans i18nKey="wizardStepsProcessOverviewButtonText" />
            </span>

            <span
              className="ms-3"
              onClick={() => {
                navigate(
                  {
                    pathname: Paths.ContactChannels,
                  },
                  {
                    state: { from: loc.pathname },
                  }
                );
              }}
              data-gi="show-all-steps"
            >
              <Trans i18nKey="ContactChannelsPageButtonText" />
            </span>
          </Col>
        </Row>
      </Container>

      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <Row className="justify-content-center text-center mt-md-3 pt-md-3">
            <Col xs={12} md={10} lg={8} className="pb-2">
              <Title className="mx-1" title={t("OrderOverviewTitle")} />
            </Col>

            <Col xs={12} md={10} lg={8} className="copy mb-md-3 pb-md-3">
              <span>
                <Trans i18nKey={"OrderOverviewDescription"} />
              </span>
              <br />
              <span style={{ color: TechemTheme.colors.accent }}>
                <Trans i18nKey={"OrderOverviewDescriptionHint"} />
              </span>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <OrderOverview onboardingInfo={onboardingInfo} />
          </Row>
        </Container>
      </main>

      <Footer />
    </div>
  ) : (
    <Container className="m-auto" style={{ transitionDuration: "350ms" }}>
      <Row className="justify-content-center">
        <Col xs="auto" className="d-flex vh-100">
          <LoadingSpinner topLevelStylingOverrides={{ margin: "auto" }} />
        </Col>
      </Row>
    </Container>
  );
};

export default connect(mapStateToProps)(OnboardingOverviewPage);
