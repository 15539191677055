import React from "react";
import { Row, Col } from "react-bootstrap";
import { TechemTheme } from "@techem/techem-theme";
import Icon, { ICONSIZE } from "./Icon";
import { useStyletron } from "styletron-react";
import { minBreakpoints } from "theme/mediaQueries";
import { useMediaQuery } from "tools/hooks/useMediaQuery";
import { CSSAssignBorderRadius } from "services/tools/CSSHelper";
import { CheckListButton } from "../pages/OBCFlow/OBCScheduled/CheckListButton";
import MailTo from "./MailTo";
import { useTranslation } from "react-i18next";

interface HintBoxProps {
  iconSrc: string;
  title: string;
  text: string;
  iconAlt?: string;
}

const HintBox: React.FC<HintBoxProps> = ({
  iconSrc,
  title,
  text,
  iconAlt = "",
}) => {
  const [css] = useStyletron();
  const { t } = useTranslation();

  const isMdOrAbove = useMediaQuery(`(min-width: ${minBreakpoints.md}px)`);
  const email = t("welcomeEmail") || "";

  return (
    <Row
      className={
        "justify-content-center mt-3 mb-4 copy " +
        css({
          color: TechemTheme.colors.contentSecondary + "14",
        })
      }
    >
      <Col xs={12}>
        <div
          className={css({
            background: TechemTheme.colors.backgroundTertiary,
            ...CSSAssignBorderRadius("8px"),
            padding: "16px 20px 24px 20px",
            textAlign: "center",
          })}
        >
          <Row className="d-inline-flex">
            <Col className={`me-md-1 ${isMdOrAbove ? "" : "p-0"}`}>
              <Icon
                key={"icon"}
                size={isMdOrAbove ? ICONSIZE.xxlarge : ICONSIZE.large}
                alt={iconAlt}
                iconSrc={iconSrc}
              />
            </Col>
            <Col
              xs="auto"
              md={12}
              className="mt-md-1 clr-water my-auto pb-md-2 text-bold"
            >
              <span>{title}</span>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} className={css({ maxWidth: "720px" })}>
              <p
                className={css({
                  color: TechemTheme.colors.contentSecondary,
                  fontFamily: "Univers LT Pro",
                  fontStyle: "normal",
                })}
              >
                {text}
              </p>
              <p
                className={css({
                  color: TechemTheme.colors.contentSecondary,
                  fontFamily: "Univers LT Pro",
                  fontStyle: "normal",
                })}
              >
                {t("OBCScheduledPageDisabledPhotoUploadText1")}
              </p>
              <p
                className={css({
                  color: TechemTheme.colors.contentSecondary,
                  fontFamily: "Univers LT Pro",
                  fontStyle: "normal",
                })}
              >
                {t("OBCScheduledPageDisabledPhotoUploadText2")}
                <MailTo
                  email={email}
                  label={email}
                  subject={"Welcome Service Fotoupload"}
                />
              </p>
            </Col>
          </Row>
          {isMdOrAbove && (
            <Row
              className={`justify-content-center ${isMdOrAbove ? "pt-4" : ""}`}
            >
              <Col xs={10} sm={7} md={5} lg={4} xl={4} className="mb-2">
                <CheckListButton />
              </Col>
            </Row>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default HintBox;
