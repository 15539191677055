import React from "react";
import { ErrorPage } from "../ErrorPage";
import LoginErrorHint from "./LoginErrorHintMessage";

const LoginErrorPage = () => {
  return (
    <ErrorPage title={"Aktuell ist ein Login nicht möglich"}>
      <LoginErrorHint />
    </ErrorPage>
  );
};

export { LoginErrorPage };
