import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { TechemTheme } from "@techem/techem-theme";
import PreferredTimeSlotPickerElement from "./PreferredTimeSlotPickerElement";
import { PreferredTimeSlotContext } from "contexts/PreferredTimeSlotContext";
import { useMediaQuery } from "tools/hooks/useMediaQuery";
import { minBreakpoints } from "theme/mediaQueries";

interface Props {
  submitChosenTimeslotsHandler: () => void;
  onSetPreferredTimeslotButtonClick: (id: number) => void;
}

const PreferredTimeSlotPicker: React.FC<Props> = ({
  onSetPreferredTimeslotButtonClick,
}) => {
  const { preferredTimeslots } = useContext(PreferredTimeSlotContext);
  const isMdOrAbove = useMediaQuery(`(min-width: ${minBreakpoints.md}px)`);

  const isDisabled = (id: number) => {
    if (id === 0) return false; // first element
    return (
      !!!preferredTimeslots[id - 1].date &&
      !!!preferredTimeslots[id - 1].timeOfDay
    );
  };

  return (
    <Row className="justify-content-center">
      {preferredTimeslots.map((preferedTimeSlot, index) => {
        const isFirst = index === 0;
        const isLast = index === preferredTimeslots.length - 1;
        return (
          <Col
            key={index}
            xs={12}
            sm={10}
            md={4}
            lg={3}
            style={{
              marginBottom: "1px",
              backgroundColor: TechemTheme.colors.backgroundSecondary,
              borderTopLeftRadius: isFirst ? "8px" : "0",
              borderTopRightRadius:
                (isFirst && !isMdOrAbove) || (isLast && isMdOrAbove)
                  ? "8px"
                  : "0",
              borderBottomLeftRadius:
                (isLast && !isMdOrAbove) || (isFirst && isMdOrAbove)
                  ? "8px"
                  : "0",
              borderBottomRightRadius: isLast ? "8px" : "0",
            }}
            className="px-md-4 py-md-3 position-relative"
          >
            <PreferredTimeSlotPickerElement
              preferredTimeSlot={preferedTimeSlot}
              disabled={isDisabled(index)}
              onSetPreferredTimeslotButtonClick={
                onSetPreferredTimeslotButtonClick
              }
              id={index}
              isLast={isLast}
            />
          </Col>
        );
      })}
    </Row>
  );
};

export default PreferredTimeSlotPicker;
