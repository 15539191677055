import React, { useState } from "react";
import Header from "layout/Header";
import Footer from "layout/Footer";
import { Row, Col, Container } from "react-bootstrap";
import { TechemTheme } from "@techem/techem-theme";
import { useNavigate } from "react-router-dom";
import { Paths } from "Routes";
import Title from "components/Title";
import PageDescription from "components/PageDescription";
import { Trans, useTranslation } from "react-i18next";
import {
  OrderType,
  setSelectedPropertyIndex,
  StoreObject,
} from "reducers/onboardingInformationSlice";
import { connect } from "react-redux";
import { Button } from "@techem/ui-components";
import { SIZE } from "baseui/button";
import { Radio, RadioGroup } from "baseui/radio";
import store from "store";
import { TrackerUtil } from "tracking/TrackerUtil";
import { CSSAssignBorderRadius } from "services/tools/CSSHelper";

const mapStateToProps = (state: any) => {
  return {
    onboardingInfo: state.onboardingInfo,
  };
};

interface Props {
  onboardingInfo?: StoreObject;
}

const PropertySelectionPage: React.FC<Props> = ({ onboardingInfo }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [selectedIndex, setSelectedIndex] = useState(
    onboardingInfo!.selectedPropertyIndex !== undefined
      ? onboardingInfo!.selectedPropertyIndex
      : 0
  );

  const getPropertyInfo = (index: number) => {
    return onboardingInfo && index >= 0
      ? onboardingInfo.userData.properties[index]
      : undefined;
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />

      <main className="flex-grow-1 flex-shrink-1">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={8} lg={5}>
              <Title className="mx-1" title={t("propertySelectionPageTitle")} />

              <PageDescription className="mx-1 mt-3 mb-0">
                {t("propertySelectionPageDescription")}
              </PageDescription>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12}>
              <Row className="justify-content-center">
                <Col xs={12} md={8} lg={6} className="">
                  <RadioGroup
                    value={`option-${selectedIndex}`}
                    onChange={(e) =>
                      setSelectedIndex(+e.currentTarget.value.split("-")[1])
                    }
                  >
                    {onboardingInfo!.userData.properties.map(
                      (property, index, array) => (
                        <Radio
                          key={index}
                          value={`option-${index}`}
                          overrides={{
                            Root: {
                              style: ({ $theme }) => ({
                                width: "100%",
                                backgroundColor:
                                  TechemTheme.colors.backgroundSecondary,
                                marginTop: "8px",
                                marginRight: "auto",
                                marginBottom: "8px",
                                marginLeft: "auto",
                                ...CSSAssignBorderRadius("8px"),
                              }),
                              props: {
                                "data-gi": `property-${
                                  property.propertyNumber
                                } td-order-${
                                  property.orders.find(
                                    (o) => o.type === OrderType.TechemDirect
                                  )!.orderNumber
                                }`,
                              },
                            },
                            Label: {
                              component: () => (
                                <Row style={{ width: "calc(100% - 72px)" }}>
                                  <Col xs={12} className="copy m-3">
                                    <Row
                                      className="copy justify-content-end"
                                      data-gi="address"
                                    >
                                      <Col
                                        xs="auto"
                                        className="ps-0 text-bold"
                                        data-gi="entry-title"
                                      >
                                        <Trans i18nKey="propertySelectionPageAddressLabel"></Trans>
                                      </Col>
                                      <Col xs className="px-0"></Col>
                                      <Col
                                        xs="auto"
                                        className="pe-0"
                                        style={{ textAlign: "right" }}
                                      >
                                        <Row className="justify-content-end">
                                          <Col
                                            xs={12}
                                            data-gi="entry-value-street-housenumber"
                                          >
                                            {`${
                                              getPropertyInfo(index)!.address
                                                .street
                                            } ${
                                              getPropertyInfo(index)!.address
                                                .houseNumber
                                            }`}
                                          </Col>
                                        </Row>
                                        <Row className="justify-content-end">
                                          <Col
                                            xs={12}
                                            data-gi="entry-value-zip-city"
                                          >
                                            {`${
                                              getPropertyInfo(index)!.address
                                                .zip
                                            } ${
                                              getPropertyInfo(index)!.address
                                                .city
                                            }`}
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>

                                    <Row
                                      className="copy justify-content-end"
                                      data-gi="number-of-apartments"
                                    >
                                      <Col
                                        xs="auto"
                                        className="ps-0 text-bold"
                                        data-gi="entry-title"
                                      >
                                        <Trans i18nKey="propertySelectionPageApartmentsLabel"></Trans>
                                      </Col>
                                      <Col xs className="px-0"></Col>
                                      <Col xs="auto" className="pe-0 text-end">
                                        <Row className="justify-content-end">
                                          <Col xs={12} data-gi="entry-value">
                                            {`${
                                              getPropertyInfo(index)!
                                                .numberOfApartments
                                            }`}
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>

                                    <Row
                                      className="copy justify-content-end"
                                      data-gi="property-number"
                                    >
                                      <Col
                                        xs="auto"
                                        className="ps-0 text-bold"
                                        data-gi="entry-title"
                                      >
                                        <Trans i18nKey="propertySelectionPagePropertyNumberLabel"></Trans>
                                      </Col>
                                      <Col xs className="px-0"></Col>
                                      <Col
                                        xs="auto"
                                        className="pe-0"
                                        style={{ textAlign: "right" }}
                                      >
                                        <Row className="justify-content-end">
                                          <Col xs={12} data-gi="entry-value">
                                            {`${
                                              getPropertyInfo(index)!
                                                .propertyNumber
                                            }`}
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              ),
                            },
                            RadioMarkOuter: {
                              style: ({ $theme }) => ({
                                alignSelf: "start",
                                marginTop: "16px",
                                marginBottom: "16px",
                                marginLeft: "16px",
                                marginRight: "16px",
                              }),
                            },
                          }}
                        ></Radio>
                      )
                    )}
                  </RadioGroup>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col xs={12} md={8} lg={5} className="">
                  <Row className="justify-content-center">
                    <Col xs="auto" className="pt-3">
                      <Button
                        size={SIZE.large}
                        onClick={() => {
                          store.dispatch(
                            setSelectedPropertyIndex(selectedIndex)
                          );
                          // Added selected property number to have same data available when reloading this and following pages.
                          navigate(
                            `${Paths.Default}?propertyNumber=${
                              store.getState().onboardingInfo.userData
                                .properties[selectedIndex!].propertyNumber
                            }`
                          );
                        }}
                        additionalTestId="select-property"
                        trackClickUsingTestId={(buttonIdentifier) => {
                          TrackerUtil.trackBtnClick(
                            `${TrackerUtil.getPageName()}_button_${buttonIdentifier}`,
                            `button_${buttonIdentifier}`
                          );
                        }}
                      >
                        <Trans i18nKey="propertySelectionPageSelectPropertyButtonText"></Trans>
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </main>

      <Footer />
    </div>
  );
};

export default connect(mapStateToProps)(PropertySelectionPage);
