import { parse, stringify } from "query-string";
import { IS_DEV_LOCAL } from "config";

export const generateURLQueryForAPILoginCall = () => {
  var urlQueryParams = Object.create(null);

  if (IS_DEV_LOCAL) {
    urlQueryParams.local_dev = "true";
  }

  const query = parse(
    window.location.hash.split("?").length > 0
      ? window.location.hash.split("?")[1]
      : ""
  );

  if (!query["client_meta"]) {
    // Only add client_meta if it's not there yet.

    const path =
      window.location.hash.split("?").length > 0
        ? window.location.hash.split("?")[0].replace("#", "")
        : "";

    const clientMeta = {
      preferredRoute: path,
      ...query,
    };
    const clientMetaEncoded = encodeURIComponent(JSON.stringify(clientMeta));

    urlQueryParams.client_meta = clientMetaEncoded;
  }

  return stringify(urlQueryParams).length > 0
    ? `?${stringify(urlQueryParams)}`
    : "";
};

export const generateURLQueryForAPILogoutCall = () => {
  var urlQueryParams = Object.create(null);

  if (IS_DEV_LOCAL) {
    urlQueryParams.local_dev = "true";
  }

  return stringify(urlQueryParams).length > 0
    ? `?${stringify(urlQueryParams)}`
    : "";
};

export const generateApplicationEnvironmentFromLoginRedirectUrl = () => {
  const hashComponents = window.location.hash
    .replace("#", "")
    .replace("/", "")
    .split("?");
  const clientMeta = JSON.parse(
    decodeURIComponent(
      parse(hashComponents.length > 0 ? hashComponents[1] : hashComponents[0])
        .client_meta as string
    )
  );

  return clientMeta;
};
