import * as React from "react";
import { Breadcrumbs } from "baseui/breadcrumbs";
import { StyledLink } from "baseui/link";
import arrowRightIcon from "assets/icons/png/3x/icn-arrow-right@3x.png";
import { TechemTheme } from "@techem/techem-theme";
import { styled } from "baseui";

interface ListItem {
  href: string;
  title: string;
}

interface TechemBreadcrumbProps {
  items: ListItem[];
  currentPageTitle: string;
}

const Link = styled(StyledLink, {
  ":visited": { color: "unset !important" },
});

const TechemBreadcrumb: React.FC<TechemBreadcrumbProps> = ({
  items,
  currentPageTitle,
}) => {
  return (
    <Breadcrumbs
      overrides={{
        List: {
          style: ({ $itemIndex, $theme }) => ({
            ":visited": { color: "unset !important" },
            color: TechemTheme.colors.primary,
          }),
        },
        Separator: {
          component: () => {
            return (
              <img
                src={arrowRightIcon}
                alt="arrow-right"
                className="py-0"
                style={{
                  height: "18px",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                }}
              />
            );
          },
        },
      }}
    >
      {items.map((item) => {
        return (
          <Link className="lh-base" href={item.href}>
            {item.title}
          </Link>
        );
      })}

      <span className="fw-bold lh-base">{currentPageTitle}</span>
    </Breadcrumbs>
  );
};

export default TechemBreadcrumb;
