import { PreferredTimeSlot } from "clients/obc/types";
import { apiClient } from "lib/axios";
import { DateFormat, formatDate } from "tools/dev/DateTimeFormatHelper";

// Endpoints for making API calls for preferred time slots
const Endpoints = {
  InstallationPreferredTimeslots:
    "/onboarding/preferredTimeslots/installation/",
  OnSiteInspectionPreferredTimeslots:
    "/onboarding/preferredTimeslots/onsiteInspection/",
};

export const PreferredTimeSlotClient = {
  /**
   * Sends a request to set preferred timeslots for an installation appointment.
   * @param orderId The ID of the order.
   * @param preferredTimeSlots The preferred time slots to set.
   */
  setInstallationPreferredTimeslots(
    orderId: string,
    preferredTimeSlots: PreferredTimeSlot[]
  ) {
    // Make a POST request to the API, formatting the date of the time slots for the API call
    return apiClient.post(
      `${Endpoints.InstallationPreferredTimeslots}${orderId}`,
      preferredTimeSlots.map((item) => {
        return {
          date: formatDate(new Date(item.date!), DateFormat.DateForAPICall),
          timeOfDay: item.timeOfDay,
        };
      })
    );
  },

  /**
   * Sends a request to set preferred timeslots for an on-site inspection appointment.
   * @param orderId The ID of the order.
   * @param preferredTimeSlots The preferred time slots to set.
   */
  setOnSiteInspectionPreferredTimeslots(
    orderId: string,
    preferredTimeSlots: PreferredTimeSlot[]
  ) {
    // Make a POST request to the API, formatting the date of the time slots for the API call
    return apiClient.post(
      `${Endpoints.OnSiteInspectionPreferredTimeslots}${orderId}`,
      preferredTimeSlots.map((item) => {
        return {
          date: formatDate(new Date(item.date!), DateFormat.DateForAPICall),
          timeOfDay: item.timeOfDay,
        };
      })
    );
  },
};
