export function CustomRedirect(props: any) {
  setTimeout(() => {
    window.location.href = `${window.location.origin}/#${props.url}${
      window.location.hash.split("?").length > 1
        ? "?" + window.location.hash.split("?")[1]
        : ""
    }`;
  }, 100); // Add some minor time delay to ensure tracking is being fired prior to this component being unmounted.
  return null;
}
