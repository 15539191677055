import React from "react";
import { styled } from "styletron-react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { TechemTheme } from "@techem/techem-theme";
import bwLogoRef from "assets/images/techemlogo_final_sw.svg";
import { TrackerUtil } from "tracking/TrackerUtil";
import { useMediaQuery } from "tools/hooks/useMediaQuery";
import { md, minBreakpoints } from "theme/mediaQueries";

const LinksWrapper = styled(Row, {
  height: "auto",
});
const FooterView = styled(Col, {
  height: "80px",
  backgroundColor: TechemTheme.colors.backgroundPrimary,
  [md]: { height: "100px" },
});
const WrapperView = styled("div", {
  zIndex: 1000,
});
const ExternalLink = styled("a", {
  color: TechemTheme.colors.contentSecondary,
  textDecoration: "none",
});
const HorizontalRulerContainer = styled(Container, {
  borderBottomWidth: "1px",
  borderBottomStyle: "solid",
  borderBottomColor: TechemTheme.colors.backgroundSecondary,
});

const F = () => {
  var { t } = useTranslation();
  const isMdAndAbove = useMediaQuery(`(min-width: ${minBreakpoints.md}px)`);

  const linkSpacer = isMdAndAbove ? (
    <Col
      xs={{ span: "auto", order: 0 }}
      className="px-2 d-none d-md-block"
    ></Col>
  ) : (
    <></>
  );

  return (
    <WrapperView id="footer" className="mt-5">
      <HorizontalRulerContainer fluid className="mt-2 mt-md-4 pt-md-2 pt-0" />
      <Container>
        <Row className="justify-content-center">
          <FooterView xs="auto" className="d-none d-md-block">
            <div className="d-flex flex-column w-100 h-100">
              <a className="my-auto" href="https://www.techem.com/de/de">
                <img
                  className="d-flex my-auto mx-0"
                  alt="techem_logo_schwarzweiss"
                  style={{ width: "100px" }}
                  src={bwLogoRef}
                />
              </a>
            </div>
          </FooterView>

          <Col className="px-0 d-none d-md-block" xs></Col>

          <FooterView xs="auto" className="d-flex px-2">
            <LinksWrapper className="justify-content-center copy mx-0 my-auto">
              <Col
                xs={{ span: "auto", order: 0 }}
                md={{ span: "auto", order: 0 }}
                className="px-2 text-end"
              >
                <ExternalLink
                  href="https://www.techem.de/impressum"
                  onClick={() => {
                    TrackerUtil.trackLinkClick(
                      "footer_link_legal-notice",
                      "link_legal-notice"
                    );
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-gi="footer-link impressum"
                >
                  {t("footerImprintLabelText")}
                </ExternalLink>
              </Col>

              {linkSpacer}

              <Col
                xs={{ span: "auto", order: 4 }}
                md={{ span: "auto", order: 0 }}
                className="px-2 pt-2 mt-1 pt-md-0 mt-md-0 text-end"
              >
                <ExternalLink
                  href="https://vermieter.techem.de/onboarding#/agb"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-gi="footer-link agb"
                  onClick={() => {
                    TrackerUtil.trackLinkClick(
                      "footer_link_terms-and-conditions",
                      "link_terms-and-conditions"
                    );
                  }}
                >
                  {t("footerTermsAndConditionsLabelText")}
                </ExternalLink>
              </Col>

              {linkSpacer}

              <Col
                xs={{ span: "auto", order: 1 }}
                md={{ span: "auto", order: 0 }}
                className="px-2"
              >
                <ExternalLink
                  href="https://vermieter.techem.de/onboarding#/widerruf"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-gi="footer-link widerruf"
                  onClick={() => {
                    TrackerUtil.trackLinkClick(
                      "footer_link_revocation",
                      "link_revocation"
                    );
                  }}
                >
                  {t("footerDisclaimerLabelText")}
                </ExternalLink>
              </Col>

              {linkSpacer}

              <Col
                xs={{ span: "auto", order: 2 }}
                md={{ span: "auto", order: 0 }}
                className="px-2"
              >
                <ExternalLink
                  style={{
                    color: TechemTheme.colors.contentSecondary,
                    textDecoration: "none",
                  }}
                  href="https://vermieter.techem.de/onboarding#/datenschutz"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-gi="footer-link datenschutz"
                  onClick={() => {
                    TrackerUtil.trackLinkClick(
                      "footer_link_data-privacy",
                      "link_data-privacy"
                    );
                  }}
                >
                  {t("footerDataPrivacyLabelText")}
                </ExternalLink>
              </Col>

              {linkSpacer}

              <Col
                xs={{ span: "auto", order: 3 }}
                className="d-md-none w-100"
              />

              <Col
                xs={{ span: "auto", order: 5 }}
                md={{ span: "auto", order: 0 }}
                className="px-2 pt-2 mt-1 pt-md-0 mt-md-0"
              >
                <ExternalLink
                  href="https://www.techem.com/de/de/services/online-kuendigung"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-gi="footer-link online-kuendigung"
                  onClick={() => {
                    TrackerUtil.trackLinkClick(
                      "footer_link_termination",
                      "link_termination"
                    );
                  }}
                >
                  {t("footerTerminationLabelText")}
                </ExternalLink>
              </Col>
            </LinksWrapper>
          </FooterView>
        </Row>
      </Container>
    </WrapperView>
  );
};
export default F;
